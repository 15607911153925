.modal-customer-phone-me {
  padding: 36px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  @media (max-width: 445px) {
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  &__call-me-item {
    margin-top: 12px;
    display: flex;
    align-items: center;

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__text {
      margin-left: 6px;
    }
  }

  &__block {
    margin-top: 24px;

    &-title {}

    &-value {
      display: inline-block;
      margin-top: 8px;
      padding: 8px 12px 8px 12px;
      border-radius: 8px;
      background-color: #f7f8fa;
      font-weight: 700;
    }
  }

  &__not-answer {
    margin-bottom: -8px;
    font-weight: 700;
  }

  &__divider {
    margin: 24px 0 24px 0;
    background-color: #e7eaef;
    height: 1px;
  }
}
