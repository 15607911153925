.stock-category-edit {
  flex-basis: 55%;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__image {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;

    &-hidden {
      opacity: 0.5;
    }

    &-empty {
      border-radius: 8px;
      border: 1px solid #eaedf2;
    }

    svg {
      fill: #bec2cd;
    }

    &:hover {
      svg {
        fill: #7677bc;
      }

      .stock-category-edit__image-edit {
        visibility: visible;
      }
    }

    &-edit {
      width: 28px;
      height: 28px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      visibility: hidden;
    }
  }

  &__title {
    flex: 1;
    margin: 0 16px 0 16px;

    &-input {
      width: 100%;

      & > input {
        height: 52px !important;
        border-radius: 8px !important;
        border: 1px solid #eaedf2 !important;
      }
    }
  }

  &__position {
    width: 94px;
    height: 52px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #eaedf2;
    cursor: pointer;

    &-up,
    &-down {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #bec2cd;
      }

      &:hover {
        svg {
          fill: #7677bc;
        }
      }
    }

    &-divider {
      margin: 10px 8px 10px 8px;
      background-color: #eaedf2;
      width: 1px;
    }

    &-down {
      transform: rotate(180deg);
    }
  }

  &__visibility,
  &__remove {
    margin-left: 8px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #eaedf2;
    cursor: pointer;
  }

  &__visibility {
    &-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #858897;
    }

    &-active {
      svg {
        fill: #bec2cd;
      }

      &:hover {
        svg {
          fill: #7677bc;
        }
      }
    }

    &-disable {
      svg {
        fill: #bec2cd;
      }

      &:hover {
        svg {
          fill: #bec2cd;
        }
      }
    }
  }

  &__remove {
    svg {
      stroke: #bec2cd;
    }

    &:hover {
      svg {
        stroke: #eb5757;
      }
    }

    &-disable {
      svg {
        stroke: #bec2cd;
      }

      &:hover {
        svg {
          stroke: #bec2cd;
        }
      }
    }
  }

  &__add {
    margin-top: 24px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &-text {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #7677bc;
    }
  }
}
