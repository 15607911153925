.stock-category {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__image {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;

    &-hidden {
      opacity: 0.5;
    }

    &-empty {
      border-radius: 8px;
      border: 1px solid #eaedf2;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #bec2cd;
    }
  }

  &__info {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 24px;
    cursor: pointer;

    @media (max-width: 445px) {
      padding-right: 12px;
    }

    &-desc {
      flex: 1;
      margin-left: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__dot {
    width: 4px;
    height: 4px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: #bec2cd;

    @media (max-width: 445px) {
      display: none;
    }
  }

  &__added-products {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: pre;
    color: #d8553f;

    @media (max-width: 445px) {
      margin-top: 2px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__group-products {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: pre;
    color: #d8553f;

    @media (max-width: 445px) {
      margin-top: 2px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__visible {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #858897;
    }
  }

  &__title {
    margin-right: 6px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    max-width: 55%;

    @media (max-width: 445px) {
      font-size: 16px;
      line-height: 20px;
      max-width: 100%;
    }

    &-info {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #858897;
      white-space: pre;

      @media (max-width: 445px) {
        margin-top: 2px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__action {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    transform: rotate(180deg);

    &-opened {
      transform: rotate(0deg);
    }
  }

  &__subcategory-header {
    width: 100%;
    margin: 12px 14px 8px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &__rating {
      &-title {}

      &-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &__products {
    margin: 12px 0 12px -12px;
    padding-right: 6px;
    max-height: 824px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    &-modal {
      max-height: 424px;
    }
  }

  &__subcategories {
    margin-left: 72px;
    border-top: 1px solid #eef1f6;
  }

  &__subcategory {
    margin-top: 16px;

    display: flex;

    &-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &-info {
      margin-left: 6px;
      margin-right: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #858897;
    }
  }
}
