.tags-container {
  display: flex;
  flex-direction: column;
  padding: 32px 0 0 32px;
  height: 100%;

  @media (max-width: 445px) {
    padding: 24px 0 20px 20px;
  }

  &__placeholder {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c4c4c4;

    &__icon {
      position: relative;
      bottom: 2px;
      margin-left: 10px;
    }
  }

  &__list {
    margin-top: 20px;
    padding: 24px 0 24px 8px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 445px) {
      margin-top: 8px;
    }

    &__new-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 190px;
      border-radius: 25px;
      padding: 10px;
      margin: 30px 30px 0 0;
      color: #0d71bb;
      border: 1px solid #d5d5d5;
      transition: transform 0.2s ease;

      &:hover {
        cursor: pointer;
        z-index: 5;
        transform: translateY(-3px);
      }

      &__text {
        font-size: 14px;
      }
    }
  }
}
