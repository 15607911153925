.order {
  &__block {
    &-row {
      display: flex;
    }

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-padding {
      margin-top: 24px;
    }

    &-last {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &-item {
      flex: 1;
    }

    &-title {
      margin-bottom: 14px;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    }

    &-label {
      font-size: 16px;
      font-weight: 400;
      color: #a0a3b5;
      line-height: 24px;

      @media (max-width: 445px) {
        font-size: 14px;
      }
    }

    &-text {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 445px) {
        font-size: 15px;
      }
    }

    &-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #bec2cd;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &__header {
    &-created {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #a0a3b5;
    }

    &-id {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__edit-btn {
    margin-left: 10px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #f2f3f6;
    cursor: pointer;

    &__icon {
      font-size: 16px !important;
      line-height: 24px !important;
      width: 22px !important;
      height: 22px !important;
      margin: 0 !important;
      color: #a0a3b5 !important;
    }
  }

  &__btn {
    background-color: #7677bc !important;
    height: 40px;
    border-radius: 6px !important;
  }

  &__btn-outline {
    min-width: 105px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    height: 40px;
    border: 1px solid #7677bc;
    border-radius: 6px !important;
    color: #7677bc !important;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }
}
