.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__message-error {
    text-align: left;
  }

  &__message {
    text-align: center;
  }
}
