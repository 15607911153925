.modal-confirm {
  padding: 32px;
  border-radius: 16px;

  @media (max-width: 445px) {
    padding: 18px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 445px) {
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
    }
  }

  &__text {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #858897;
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-left: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7677bc;
    height: 48px;
    border-radius: 6px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;

    &-alert {
      background-color: #d8553f;
    }
  }

  &__btn-outline {
    margin-left: 0;
    margin-right: 12px;
    border: 2px solid #7677bc;
    color: #7677bc;
    background-color: white;
  }
}
