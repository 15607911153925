.analytics__block__stat {
  display: flex;
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &-color {
    margin-top: 4px;
    margin-right: 12px;
    height: 14px;
    width: 20px;
    border-radius: 3px;
  }

  &-label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #777e96;

    &__icon {
      margin-left: 6px !important;
      color: #7677bc !important;
    }
  }

  &-value {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #1a1e32;
  }
}
