.report-products-btn {
  width: 214px;
  height: 52px;
  border: 1px solid #7677bc;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px 14px 20px;
  cursor: pointer;

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__text {
    color: #7677bc;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    text-align: center;
  }
}
