.sellers-info {
  height: 100%;
  padding: 32px;

  &__list {
    margin-top: 40px;

    &-item {
      display: flex !important;
      align-items: center !important;
      margin-top: 6px !important;
      margin-bottom: 6px !important;
    }
  }
}
