.modal-order {
  padding: 36px;
  border-radius: 16px;

  @media (max-width: 445px) {
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  &__order {
    margin-top: 30px;
    display: flex;

    @media (max-width: 445px) {
      flex-direction: column;
    }
  }

  &__column {
    flex: 1;
  }

  &__column-divider {
    margin: 0 40px 0 40px;
    background-color: #e7eaef;
    width: 1px;
  }
}
