$cardWidth: 190px;
$cardHeight: 190px;

.tag-card {
  width: $cardWidth;
  height: $cardHeight;
  border-radius: 25px;
  margin: 30px 30px 0 0;
  transition: transform 0.2s ease;
  overflow: hidden;
  word-break: break-word;
  border: 1px solid #e1e1e1;
  position: relative;

  &:hover {
    cursor: pointer;
    z-index: 5;
    transform: translateY(-3px);
  }

  &__image {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    width: $cardWidth / 2;
    height: $cardHeight / 2;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    position: relative;
    z-index: 100;
    margin-top: 25px;
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    line-height: 26px;
    color: white;
    text-shadow: 0 0 0 #797979;
  }

  &__icons {
    display: flex;
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;

    &__icon {
      color: white;
      transition: color 0.5s ease;
      min-height: 20px;

      &-trash {
        &:hover {
          color: red;
        }
      }

      &-edit {
        &:hover {
          color: #26c24e;
        }
      }
    }
  }

  &__count {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 1px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
  }
}
