.customer__comment {
  &__button {
    margin-top: 12px;
  }

  &__change-actions {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    & > div {
      width: 160px;
      min-width: 160px;
      margin-right: 16px;
    }
  }

  &__comment {
    margin-top: 16px;
  }
}
