.moderate-product__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-btn {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    cursor: pointer;

    &-approve {
      background-color: #5bc258;
    }

    &-reject {
      margin-left: 16px;
      background-color: #d8553f;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }

    &-text {
      margin-left: 2px;
      color: white;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
