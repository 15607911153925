.seller {
  height: 100%;
  overflow: auto;
  padding: 24px;
  position: relative;

  @media (max-width: 445px) {
    padding: 48px 18px 18px 18px;
  }

  &__back {
    position: absolute;
    top: 12px;
    left: 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 52px;
    text-align: center;
  }

  &__top-image-container {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__shop {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 0;

    &__image {
      width: 100%;
      max-width: 550px;
      height: 340px;
      cursor: pointer;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      object-fit: cover;
      z-index: 1;

      @media (max-width: 445px) {
        height: 240px;
      }
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #9f3a38;
      z-index: 3;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__profile {
    display: flex;
    margin: 12px;
    align-items: center;

    &__avatar {
      position: relative;
      width: 92px;
      height: 92px;
      background-size: cover;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      border: 2px solid white;
      overflow: hidden;

      @media (max-width: 445px) {
        width: 64px;
        height: 64px;
        min-width: 64px;
        min-height: 64px;
      }

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &-placeholder {
          position: absolute;
          z-index: 2;
        }
      }
    }

    &__info {
      margin: 16px;

      &__organization {
        font-size: 16px;
        word-break: break-word;
      }

      &__name {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        word-break: break-word;
      }

      &__hidden {
        color: #ff4f4d;
      }

      &__nickname {
        margin-top: 10px;
        font-size: 16px;
        word-break: break-word;
      }

      &__location {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        word-break: break-word;
      }
    }

    &__edit {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        background-color: rgba(133, 133, 133, 0.1);
      }
    }
  }

  &__select {
    &-field {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 24px;

      &__value {
        width: 320px;
        max-width: 320px;
      }
    }
  }

  &__categories {
    margin-top: 12px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 4px 8px 6px 0;

    &__item {
      height: 32px;
      padding-left: 18px;
      padding-right: 18px;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: rgba(#52538e, 0.15);
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &-selected {
        border: 1px solid #52538e;
      }

      &-text {
        font-size: 16px;
        font-weight: bold;
        color: #52538e;
        white-space: nowrap;
      }
    }
  }

  &__products {
    display: flex;
    flex-wrap: wrap;
  }

  &__products__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
    height: 48px;

    &-tab {
      height: 40px;
      margin-left: 24px;
      margin-right: 24px;
      text-align: center;
      flex: 1;
      max-width: 200px;
      font-size: 20px;
      font-weight: 700;

      @media (max-width: 445px) {
        font-size: 18px;
        margin-left: 12px;
        margin-right: 12px;
      }

      &:hover {
        cursor: pointer;
      }

      &__active {
        border-bottom: 4px solid #525586;
      }
    }
  }

  &__products__add {
    margin-top: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 280px;
      border-radius: 24px;
      background-color: #525586;

      &:hover {
        cursor: pointer;
      }

      &__text {
        color: white;
        font-size: 18px;
      }
    }
  }

  &__report {
    display: flex;
    align-items: center;

    &-text {
      font-size: 16px;
      font-weight: 400;
      padding-right: 8px;
      color: #6162be;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
