.orders-map {
  padding: 12px;

  &__map {
    border-radius: 16px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
  }

  &-list {
    margin-top: 24px;
  }

  &__filter {
    display: flex;
    align-items: center;

    &__field {
      margin-right: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-label {
        max-width: 160px;
        margin-right: 16px;
      }

      &-value {
        width: 300px;
        max-width: 90%;
      }
    }

    &__tabs {
      margin: 0 16px 0 0px;
      padding: 4px 7px 4px 7px;
      height: 52px;
      border-radius: 8px;
      border: 1px solid #eaedf2;
      display: flex;
      width: 184px;
      background-color: white;

      @media (max-width: 445px) {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 2px;
        width: 100%;
      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86px;
        cursor: pointer;
        color: #858897;
        border-radius: 8px;

        @media (max-width: 445px) {
          flex: 1;
        }

        &-active {
          background-color: #7677bc;
          color: white;
        }
      }
    }
  }

  &__legend {
    position: absolute;
    left: 16px;
    bottom: 24px;
    background-color: white;
    border-radius: 16px;
    padding: 6px 16px 16px 16px;

    &-title {
      font-size: 16px;
      font-weight: 700;
      margin-top: 8px;
      margin-bottom: 10px;
    }

    &__item {
      display: flex;
      align-items: center;
      height: 24px;
      margin-top: 10px;

      &-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }

      &-name {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
