.categories {
  padding: 28px 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 612px;
    height: 196px;
    padding: 36px;
    background-color: white;
    border-radius: 16px;

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }

    &-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #777e96;
    }

    &-btn {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__stock-edit {
    padding: 36px;
    background-color: white;
    border-radius: 16px;

    &__header {
      display: flex;
    }

    &__block {
      width: 100%;
    }

    &__list {
      margin-top: 32px;
    }

    &__item {
      display: flex;
      margin-bottom: 48px;

      &-subcategories {
        flex-basis: 45%;
      }
    }

    &__category {
      flex-basis: 55%;

      &-title {
        flex-basis: 55%;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__subcategory {
      flex-basis: 45%;

      &-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__add {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      &-text {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #7677bc;
      }
    }
  }

  &__stock {
    padding: 36px;
    border-radius: 16px;
    background-color: white;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &-text {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__divider {
      margin: 20px 0 20px 0;
      background-color: #eef1f6;
      height: 1px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 32px 36px 0 0;
  }

  &__divider {
    margin: 0 32px 0 32px;
    background-color: #e0e5ec;
    width: 1px;

    &-empty {
      margin: 0 32px 0 32px;
      width: 1px;
    }
  }
}
