.seller-update {
  &__field {
    margin-top: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-label {
      max-width: 200px;
      width: 200px;
      margin-right: 16px;
    }

    &-value {
      width: 300px;
    }

    &-image__wrapper {
      position: relative;
      padding: 3px;
      border: 3px solid #0d71bb;
    }

    &-image-container {
      height: 225px;
      max-width: 400px;
      overflow: hidden;
      position: relative;

      @media (max-width: 445px) {
        height: 200px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &__actions {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        &-btn {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-left: 8px;
          margin-right: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: rgba(133, 133, 133, 0.45);

          &-icon {
            width: 36px;
            height: 36px;
            margin: 0 !important;
            color: white;
          }

          &:hover {
            cursor: pointer;
            font-size: 1.25em !important;
          }
        }
      }
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.42);
    }

    &-avatar__wrapper {
      padding: 3px;
      border: 3px solid #0d71bb;
      border-radius: 50%;
      position: relative;
    }

    &-avatar {
      border-radius: 50%;
      height: 92px;
      width: 92px;
      overflow: hidden;
      position: relative;

      &__actions-btn {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: rgba(133, 133, 133, 0.45);

        &-icon {
          width: 36px;
          height: 36px;
          margin: 0 !important;
          color: white;
          font-size: 1.1em !important;
        }

        &:hover {
          cursor: pointer;
          font-size: 1.25em !important;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
