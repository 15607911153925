.moderate-product {
  &__block {
    &-row {
      display: flex;
    }

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-padding {
      margin-top: 24px;
    }

    &-last {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &-item {
      flex: 1;
    }

    &-title {
      margin-bottom: 14px;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    }

    &-label {
      font-size: 16px;
      font-weight: 400;
      color: #a0a3b5;
      line-height: 24px;

      @media (max-width: 445px) {
        font-size: 14px;
      }
    }

    &-text {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 445px) {
        font-size: 15px;
      }
    }

    &-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #bec2cd;
      margin-left: 8px;
      margin-right: 8px;
    }

    &-changed {
      display: inline-flex;
      margin-left: -12px;
      padding: 4px 12px 4px 12px;
      background-color: #fff4dd;
      border-radius: 12px;
    }
  }

  &__header {
    &-created {
      font-size: 12px;
      font-weight: 400;
      color: #a0a3b5;
    }

    &-id {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
