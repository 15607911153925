.home {
  display: flex;
  width: 100%;
  height: 100%;

  &__sidebar {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 100%;
    margin-left: 0;
    transition: margin-left 0.5s;

    &-hide {
      margin-left: -240px !important;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    margin-top: 68px;
  }

  &__area {
    width: 100%;
    transition: width 0.5s;

    &-with-sidebar {
      width: calc(100% - 240px);
    }
  }
}

.sidebar-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-size: 18px;
  flex-direction: row-reverse;

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-text {
    &-sub {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 28px;
    height: 28px;
  }
}
