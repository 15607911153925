.search-field {
  height: 52px;
  min-width: 252px;
  flex: 2;
  position: relative;

  &__input {
    width: 100%;
  }

  &__clear {
    position: absolute;
    width: 36px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 62px;
    cursor: pointer;
  }

  &__label {
    height: 52px;
    background-color: #7677bc !important;
    border-radius: 0 10px 10px 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  input {
    height: 52px;
    border-radius: 10px 0 0 10px !important;
  }

  &__icon {
    color: white !important;
    margin: 0 6px 0 6px !important;
    font-size: 19px !important;
  }
}
