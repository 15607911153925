.market-analytics__content {
  &-blocks {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-right: 24px;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 750px) {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &-title {
    margin-bottom: 12px;
    min-height: 48px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #858897;
  }
}

.analytics__block {
  display: flex;
  flex-direction: column;
  width: 580px;
  height: 358px;
  border-radius: 16px;
  box-shadow: 0 6px 60px rgba(9, 29, 52, 0.03);
  background-color: white;

  &-container {}

  &-title {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  &-header {
    display: flex;
    align-items: center;
    margin: 0 32px 0 48px;
    padding: 24px 0 4px 0;
    border-bottom: 1px solid #e7e8ea;

    &__tab {
      margin-right: 22px;
      margin-bottom: 18px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      color: #a0a3b5;
      cursor: pointer;

      &-active {
        color: #1d1d1b;
      }

      &-icon {
        margin-left: 6px !important;
        color: #7677bc !important;
      }
    }
  }

  &-chart {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    height: 100%;

    &__legend {
      display: flex;
      flex-direction: column;
    }
  }
}

