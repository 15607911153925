.order__client {
  &-phone {
    color: #6162be;
    cursor: pointer;
  }

  &-call-me {
    margin-top: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {
      color: #7677bc;
    }

    &__icon  {
      width: 23px;
      height: 23px;
    }
  }
}
