.couriers-role {
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  transition: height 1s;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-left: 24px;

      &__check {
        margin-left: 8px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 32px;
  }
}
