.modal-reject-product {
  padding: 32px;
  border-radius: 16px;

  @media (max-width: 445px) {
    padding: 18px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 445px) {
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
    }
  }

  &__info {
    display: flex;
    min-height: 64px;
    margin-top: 16px;
    padding: 8px;
    border-radius: 12px;
    background-color: #f7f8fa;

    @media (max-width: 445px) {
      display: inline-flex;
      padding: 12px 8px 12px 8px;
    }

    &-value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-right: 8px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;

      @media (max-width: 445px) {
        font-size: 12px;
        line-height: 16px;
      }

      &-error {
        color: #d8553f;
        font-size: 14px;
        font-weight: 400;
        width: 100%;

        @media (max-width: 445px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &-photo {
      flex-basis: 8%;
      min-width: 88px;

      &-image {
        width: 74px;
        height: 60px;
        border-radius: 10px;
      }
    }

    &-name {
      flex-basis: 46%;
      min-width: 160px;

      @media (max-width: 445px) {
        min-width: 150px;
      }
    }

    &-seller {
      flex-basis: 38%;
      min-width: 125px;
      flex-direction: row;
      align-items: center;
      justify-content: start;

      @media (max-width: 445px) {
        min-width: 90px;
      }

      &-common {
        &__icon {
          margin-left: 12px;
        }
      }
    }
  }

  &__text {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #858897;
  }

  &__field {
    &-label {
      margin-top: 24px;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
      margin-left: 24px;
      width: 184px;
      min-width: 184px;
    }
  }
}
