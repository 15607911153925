.couriers-routes {
  &-content {
    padding: 12px;
  }

  &-map {
    position: relative;
    border-radius: 16px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    &__order-info {
      max-width: 240px;
      line-height: 20px;
      font-size: 14px;

      &__header {
        display: flex;
        align-items: center;
        cursor: pointer;

        &-id {
          color: #6162be;
          font-weight: 700;
        }

        &-dot {
          margin-left: 8px;
          margin-right: 8px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #a4aabf;
        }

        &-status {
          font-weight: 400;
        }
      }

      &__row {
        margin-top: 8px;
      }

      &__label {
        font-weight: 700;
        padding-right: 4px;
      }

      &__phone {
        color: #6162be;
        cursor: pointer;
      }

      &__divider {
        width: 100%;
        height: 1px;
        background-color: #e7eaef;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      &__btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        border-radius: 8px;
        background-color: #7677bc;
        font-weight: 700;
        font-size: 12px;
        line-height: 24px;
        color: white;
        cursor: pointer;
      }
    }

    &__legend {
      max-height: 420px;
      width: 342px;
      overflow: auto;
      position: absolute;
      left: 16px;
      bottom: 16px;
      border-radius: 16px;
      background-color: white;

      &-back {
        display: flex;
        align-items: center;
        padding: 20px 16px 16px 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #858897;
        cursor: pointer;
      }

      &-title {
        padding: 20px 16px 16px 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #777e96;
      }

      &-divider {
        margin-top: 16px;
        margin-bottom: 14px;
        height: 1px;
        width: 100%;
        background-color: #d9dfe4;
      }

      &-courier {
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        padding-bottom: 16px;
        cursor: pointer;

        &-header {
          margin-left: 0;
          margin-right: 0;
          padding-left: 16px;
          padding-right: 16px;
        }

        &:not(:last-child) {
          margin-bottom: 16px;
          border-bottom: 1px solid #e7e8ea;
        }

        &__info {
          margin-left: 8px;
        }

        &__name {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        &__phone {
          margin-top: 4px;
          font-weight: 400;
          font-size: 14px;
          color: #777e96;
        }

        &__busy {
          display: flex;
          align-items: center;
          margin-top: 12px;

          &-route {
            margin-left: 12px;
            padding-left: 12px;
            border-left: 1px solid #bec2cd;
          }
        }

        &__route {
          margin-top: 12px;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #858897;
          cursor: pointer;

          &-edit {
            color: #6162be;
          }

          &-icon {
            display: flex;
            align-items: center;
            margin-left: 4px;
          }
        }

        &__route-steps {
          margin-bottom: 24px;
          padding-left: 16px;
          padding-right: 8px;

          &__orders {
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;

            &-label {
              padding-right: 4px;
              font-weight: 700;
              color: #858897;
            }
          }

          &__action {
            padding: 10px 16px 16px 16px;
          }

          &__actions {

            padding: 10px 16px 16px 16px;
            display: flex;
            align-items: center;

            &-btn, &-delete {
              height: 48px;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #7677bc;
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
              color: white;
              cursor: pointer;
            }

            &-delete {
              margin-right: 16px;
              background-color: #f2f3f6;
              color: #eb5757;
            }
          }

          &__estimate {
            margin-bottom: 8px;
            padding: 12px 16px 12px 16px;
            display: flex;
            align-items: center;
            border-top: 1px solid #d9dfe4;
            border-bottom: 1px solid #d9dfe4;

            &-icon {
              width: 20px;
              height: 20px;
              margin-right: 18px;
            }

            &-duration {
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
            }

            &-distance {
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #858897;
            }

            &-dot {
              width: 3px;
              height: 3px;
              margin-right: 8px;
              margin-left: 8px;
              background-color: #bec2cd;
            }
          }
        }

        &__route-point {
          margin-bottom: 12px;
          padding-bottom: 14px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          &:not(:last-child) {
            border-bottom: 1px solid #d9dfe4;
          }

          &__text {
            margin-top: 4px;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
          }

          &__num {
            margin-top: 4px;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            color: #6162be;
            cursor: pointer;
          }

          &__address {
            margin-top: 4px;
            margin-bottom: 4px;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
          }

          &__delivered {
            margin-top: 4px;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            color: #33b04b;
          }

          &__canceled {
            margin-top: 4px;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            color: #eb5757;
          }
        }

        &__route-step {
          margin-top: 4px;
          display: flex;
          align-items: center;

          &__icon {
            position: relative;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;

            &-point {
              width: 11px;
              height: 11px;
              border: 1px solid #1d1d1b;
              border-radius: 50%;
            }

            &-dash {
              position: absolute;
              top: 20px;
              left: 9px;
              width: 1px;
              height: 21px;
              background-color: #e7e8ea;
            }
          }

          &__value {
            position: relative;
            min-width: 212px;
            width: 212px;
            padding: 10px 12px 10px 12px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #eaedf2;
            margin-left: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &__move {
              position: absolute;
              right: 0;
              top: 0;
              height: 40px;
              width: 64px;
              display: none;
              background-color: #f7f8fa;

              &-up {
                display: flex;
                align-items: center;
                cursor: pointer;
              }

              &-down {
                display: flex;
                align-items: center;
                transform: rotate(180deg);
                cursor: pointer;
              }
            }
          }

          &__remove {
            display: none;
            width: 20px;
            height: 20px;
            margin-left: 8px;
            cursor: pointer;
          }

          &:hover {
            .couriers-routes-map__legend-courier__route-step__value__move {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .couriers-routes-map__legend-courier__route-step__remove {
              display: flex;
              align-items: center;
            }
          }
        }

        &__icon {
          width: 24px;
          height: 24px;
          position: relative;
        }

        &__status {
          position: absolute;
          top: -0.5px;
          right: -4px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid white;
          background-color: #eb5757;

          &-shift {
            background-color: #33b04b;
          }
        }
      }
    }
  }

  &-list {
    margin-top: 24px;
  }

  &__filters {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }

  &-filter {
    display: flex;

    &__field {
      margin-top: 20px;
      margin-right: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-label {
        max-width: 160px;
        margin-right: 16px;
      }

      &-value {
        width: 300px;
        max-width: 90%;
      }
    }
  }
}
