.image-cropper {
  position: relative;

  &__actions {
    display: flex;
  }

  &__rotate {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading {
    width: 100%;
    height: 400px;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.42);
  }
}
