.copy-products {
  height: 100%;
  padding: 32px;
  overflow: auto;

  @media (max-width: 445px) {
    padding: 20px 20px 20px 16px;
  }

  &__message {
    max-width: 460px;
  }

  &__text {
    font-size: 16px;

    &-info {
      margin-top: 10px;
      font-size: 14px;
      color: #a0aeb5;
      max-width: 560px;
    }
  }

  &__form {
    margin-top: 28px;

    &-field {
      display: flex;
      margin-top: 8px;
      margin-bottom: 24px;

      &__value {
        width: 320px;
        max-width: 320px;
      }
    }

    &-loading {
      margin-top: 12px;
      display: flex;
      align-items: center;

      &__text {
        font-size: 20px;
        padding-right: 16px;
      }
    }
  }

  &__actions {
    margin-top: 24px;
  }
}
