.slots {
  background: #ffffff;
  box-shadow: 0 4px 64px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 36px;
  width: 612px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 26px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      cursor: pointer;
      background-color: #7677bc;
      transition: background-color 0.1s;
      color: #ffffff;

      &:hover {
        background-color: #6a6ba8;
      }

      &:active {
        background-color: #5d5d93;
      }
    }
  }

  &__title {
    color: #1a1e32;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      color: #777e96;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    &__add-slot {
      color: #7677bc;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      cursor: pointer;
      margin-right: auto;

      &__plus {
        font-size: 20px;
      }
    }

    &__buttons {
      display: flex;
      margin-top: 24px;

      &__button {
        margin-right: 29px;
      }
    }
  }
}
