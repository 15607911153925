.delivery-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;

  &__icon {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #27ae60;
  }

  &__text {
  }
}
