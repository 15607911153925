.sellers {
  height: 100%;
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 32px;

  &__operations {
    margin-top: 40px;
  }
}
