.couriers {
  &-content {
    padding: 12px;
  }

  &-map {
    border-radius: 16px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &-list {
    margin-top: 24px;
  }

  &-filter {
    display: flex;

    &__field {
      margin-top: 20px;
      margin-right: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-label {
        max-width: 160px;
        margin-right: 16px;
      }

      &-value {
        width: 300px;
        max-width: 90%;
      }
    }
  }
}
