.courier-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  width: 405px;
  height: 148px;
  position: relative;
  background: #ffffff;
  margin: 16px;
  padding: 24px;
  box-shadow: 0 4px 64px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
  transition: transform 0.15s ease-in-out, box-shadow 0.17s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 64px rgba(0, 0, 0, 0.13);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    &__name {
      line-height: 32px;

      &-number {
        color: #858897;
      }
    }

    &__status {
      padding: 4px 12px 4px 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      border-radius: 12px;
      background: #d8553f1a;
      color: #d8553f;
    }
  }

  &__info {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: #777e96;
  }

  &__in-work-count {
    font-weight: 700;
  }
}
