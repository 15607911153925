.moderate-product-item-wrapper {
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 445px) {
    display: inline-flex;
  }

  &:hover {
    background-color: #f5f7fb;
  }

  &__selected {
    background-color: #eaedf2;

    .order-item__edit-btn {
      visibility: visible;
    }
  }
}

.moderate-product-item {
  display: flex;
  min-height: 64px;
  margin-top: 6px;
  padding: 12px 12px 12px 12px;
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 445px) {
    display: inline-flex;
    padding: 12px 8px 12px 8px;
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

    @media (max-width: 445px) {
      font-size: 12px;
      line-height: 16px;
    }

    &-error {
      color: #d8553f;
      font-size: 14px;
      font-weight: 400;
      width: 100%;

      @media (max-width: 445px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__subvalue {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #a4aabf;
  }

  &__photo {
    flex-basis: 8%;
    min-width: 88px;
    position: relative;
    justify-content: flex-start;

    &-status {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid white;
      position: absolute;
      top: -10px;
      left: -10px;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }

    &-state {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid white;
      position: absolute;
      top: -5px;
      left: -5px;
    }

    &-image {
      width: 74px;
      height: 60px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  &__name {
    flex-basis: 46%;
    min-width: 160px;

    @media (max-width: 445px) {
      min-width: 150px;
    }
  }

  &__seller {
    flex-basis: 38%;
    min-width: 125px;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    @media (max-width: 445px) {
      min-width: 90px;
    }

    &-common {
      &__icon {
        margin-left: 12px;
      }
    }
  }

  &__actions {
    flex-basis: 8%;
    min-width: 60px;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }
}
