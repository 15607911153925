.markets {
  height: 100%;
  padding: 32px 40px;
  background-color: #f5f7fb;

  &-content {
    margin-top: 28px;
    height: calc(100% - 52px);
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
  }

  &__list {
    border-radius: 12px;
    background-color: white;

    &-header {
      display: flex;
      align-items: center;
      margin: 11px 30px 11px 30px;

      &__item {
        min-width: 180px;
        font-size: 12px;
        color: #a4aabf;
      }
    }
  }

  &__item-wrapper {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin: 5px 6px 4px 6px;
    padding: 24px;

    &:hover {
      cursor: pointer;
      background-color: #f5f7fb;
      color: #7677bc;
    }

    &-city {
      min-width: 164px;
      font-size: 16px;
    }

    &-name {
      padding-left: 16px;
      font-size: 16px;
    }

    &-divider {
      height: 1px;
      margin: 0 24px 0 24px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #eef1f6;
    }
  }
}
