.alert {
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  z-index: 100;
  animation: show 0.3s ease-out;

  &_message-container {
    margin: 20px 20px 30px;
    max-width: 500px;
    min-width: 300px;

    &_message {
      display: flex;

      &_content {
        flex: 1;
      }

      &_close {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

@keyframes show {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0;
  }
}
