.customer__reviews {
  &-item {
    margin-top: 12px;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #e7e8ea;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-num {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #7677bc;
      }
    }

    &__message {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__show {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7677bc;
    cursor: pointer;
  }
}
