.order__payment {
  &__delivery {
    margin-top: 8px;
    display: flex;

    &__input {
      width: 112px;
    }

    &-btn {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-left: 8px !important;
      background-color: #7677bc !important;
      color: #7677bc !important;
      width: 40px;
      height: 40px;

      &__icon {
        margin: 0 !important;
        color: white !important;
      }
    }
  }

  &__url {
    margin-bottom: 18px;
    display: flex;
    align-items: center;

    &-btn {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.order__comment {
  margin-top: 24px;

  &-text {
    margin-bottom: 12px;
  }

  &-form {
  }
}
