.customers-filter {
  padding: 24px 32px 24px 32px;

  @media (max-width: 445px) {
    padding: 16px 16px 8px 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 445px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__period {
    display: flex;
    align-items: center;

    @media (max-width: 445px) {
      flex-direction: column;
    }

    & > div {
      margin: 0 !important;
      max-width: 156px;
    }

    &-divider {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__field {
    margin-top: 12px;
    font-size: 16px;

    @media (max-width: 445px) {
      font-size: 14px;
    }

    &-column {
      display: flex;
      flex-direction: column;
    }

    &-label {
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;

      @media (max-width: 445px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &-drop {
      width: 100%;
    }

    &-value {
      min-width: 82px !important;
      margin-bottom: 12px;
    }

    &-error {
      margin-top: 12px;
      color: #9f3a38;
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.42);
    }
  }

  &__actions {
    margin-top: 20px;

    &-btn {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: #7677bc;
      font-size: 16px;
      font-weight: 700;
      color: white;
      cursor: pointer;

      &-cancel {
        height: 44px;
        background-color: white;
        color: #858897;
      }
    }
  }

  &__divider {
    margin: 24px 0 24px 0;
    background-color: #e7eaef;
    height: 1px;

    @media (max-width: 445px) {
      margin: 12px 0 12px 0;
    }
  }
}
