.slot-card {
  display: flex;
  flex-direction: column;
  width: 772px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__slot {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 10px;
      background: #F5F7FB;
      border-radius: 12px;
      font-weight: bold;
      font-size: 14px;
    }

    &__message {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      font-size: 12px;
      padding: 4px 12px 4px 12px;

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }

    &__close {
      display: flex;
      opacity: 0;
      align-items: center;
      text-align: center;
      color: #A0A3B5;
      border: 1px solid #BEC2CD;
      border-radius: 6px;
      font-weight: bold;
      font-size: 14px;
      padding: 4px 24px 4px 24px;
      cursor: pointer;
      transition: opacity .12s ease-in, border-color .1s ease-in, color .1s ease-in;
      user-select: none;

      &:hover {
        color: #838594;
        border-color: #838594;
      }

      &:active {
        color: #3e3e46;
        border-color: #3e3e46;
      }
    }

    .slot-card:hover &__close {
      opacity: 1;
    }
  }

   &__content {
     display: flex;
     flex-direction: row;

     &__right {
       display: flex;
       flex-direction: row;

       &__list {
         margin-left: 30px;
         margin-right: 30px;
         color: #858897;

         & > div {
           margin-bottom: 4px;
         }

         &__title {
           color: #1A1E32;
           font-weight: bold;
         }

         &__info {
           color: #1A1E32;
           font-weight: 500;
           margin-left: 10px;
         }
       }
     }
   }
}
