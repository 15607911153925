.seller-item {
  flex: 1;
  display: flex;
  position: relative;
  padding: 16px 16px 16px 16px;
  background-color: #f7f8fa;
  border-radius: 20px;

  @media (max-width: 445px) {
    min-width: 90%;
    //margin: 12px 0 16px 0;
  }

  &__types {
    position: absolute;
    top: 18px;
    right: 12px;

    &-item {
      margin-bottom: 4px;
    }
  }

  &__shop {
    position: relative;
    width: 80px;
    height: 80px;
    background-color: white;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 16px;

    @media (max-width: 445px) {
      width: 100px;
      height: 100px;
    }
  }

  &__avatar {
    width: 44px;
    height: 44px;
    background-size: cover;
    border-radius: 50%;
    position: absolute;
    top: 68px;
    left: 34px;
    border: 2px solid white;
    background-color: white;
    overflow: hidden;

    @media (max-width: 445px) {
      width: 56px;
      height: 56px;
      top: 72px;
      left: 22px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &-info {
    margin: 0 40px 0 16px;

    &__organization {
      margin-top: 4px;
      font-size: 14px;
      line-height: 22px;
      word-break: break-word;
      color: #858897;
    }

    &__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      word-break: break-word;
    }

    &__hidden {
      padding-left: 5px;
      color: #ff4f4d;
    }

    &__row {
      margin-top: 8px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 445px) {
        flex-wrap: wrap;
      }
    }

    &__dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #bec2cd;
      margin-left: 8px;
      margin-right: 8px;
    }

    &__nickname {
      font-size: 14px;
      line-height: 22px;
      word-break: break-word;
    }

    &__location {
      font-size: 14px;
      line-height: 22px;
      word-break: break-word;
    }

    &__added-products {
      font-size: 14px;
      line-height: 22px;
      color: #d8553f;
      white-space: nowrap;
    }
  }

  &__active {
    &:hover {
      cursor: pointer;
      background-color: rgba(133, 133, 133, 0.07);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }
}
