.notification {
  padding: 44px 0 316px 32px;
  overflow: auto;
  height: 100%;
  position: relative;

  @media (max-width: 445px) {
    padding: 44px 0 20px 20px;
  }

  &__back {
    position: absolute;
    top: 16px;
    left: 20px;
  }

  &__field {
    margin-bottom: 24px;
    font-size: 16px;

    &-error {
      margin-bottom: 12px;
      color: #9f3a38;
    }

    &-label {
      margin-bottom: 12px;
    }

    &-value {
      width: 300px;

      @media (max-width: 445px) {
        width: 90%;
      }
    }

    &-radio {
      margin-left: 24px;
    }

    &-btn {
      margin-left: 32px;
      align-self: start;
      margin-top: 4px;
    }
  }

  &__times {
    display: flex;
    max-width: 160px;

    &-actions {
      display: flex;
      margin-top: 12px;
      margin-left: 6px;

      &__minus,
      &__plus {
        margin-left: 10px;
      }
    }
  }

  &__actions {
    margin-top: 36px;
    display: flex;
    align-items: center;

    &-btn {
      width: 180px;
    }
  }

  &__users-container {
    max-width: 450px;
  }
}
