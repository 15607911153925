.seller-register {
  padding: 24px;
  height: 100%;
  overflow: auto;

  &-title {
    padding-top: 32px;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  &__field {
    margin-top: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-checkbox {
      transform: scale(1.5);
      padding-top: 4px;
      padding-left: 4px;
      width: 24px;
      height: 24px;
    }

    &-label {
      max-width: 260px;
      width: 260px;
      margin-right: 16px;
    }

    &-value {
      width: 300px;
    }

    &-radio {
      margin-left: 24px;
    }

    &-btn {
      margin-left: 32px;
      align-self: start;
      margin-top: 4px;
    }

    &-url {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &__actions {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-btn {
      width: 220px;
      margin-left: 16px !important;
      margin-right: 16px !important;
    }
  }

  &__item {
    margin-top: 12px;

    &-title {
      margin-top: 32px;
      margin-bottom: 32px;
      font-size: 22px;
      font-weight: 700;
    }
  }

  &__loader {
    margin-left: 40px;
  }
}
