.market-wrapper {
  height: 100%;
  background-color: #f5f7fb;
}

.market {
  padding: 32px 40px;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;

  &-content {
    margin-top: 24px;
  }

  &-info {
    margin-top: 32px;
    font-size: 16px;

    &__item {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;

      &-label {
        color: #777e96;
        padding-right: 8px;
      }
    }
  }

  &-edit-btn {
    width: 240px;
    height: 52px;
    margin: -36px 16px 12px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7677bc;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
  }
}
