.action {
  display: flex;
  flex-direction: column;
  margin: 12px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &__date {
    color: #A0A3B5;
    margin-bottom: 6px;
  }

  &__content {

  }
}