.sellers-markets {
  height: 100%;
  padding: 32px;
  overflow: auto;

  @media (max-width: 445px) {
    padding: 20px 8px 20px 16px;
  }

  &__help {
    font-size: 18px;
    color: rgba(133, 133, 133, 0.8);
  }
}
