.order-history {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    cursor: pointer;
  }

  &__icon {
    margin-left: 10px;
    transform: rotate(180deg)  translateY(-5px);

    &-open {
      transform: translateY(-1px);
    }
  }
}
