.order__delivery {
  &__form {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #7677bc;
    cursor: pointer;

    &-icon {
      margin: 0 !important;
      font-size: 18px !important;
    }
  }

  &__polygon {
    margin-top: 8px;
  }
}
