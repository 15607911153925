.seller-products {
  display: flex;
  flex-direction: column;
  height: 940px;

  &__modal {
    height: 540px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__info {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 24px;
  }

  &__subcategory-header {
    width: 90%;
    margin: 8px 14px 4px 14px;

    &-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__categories {
    display: flex;
    align-items: center;
    overflow: auto;
    margin-top: 24px;
    margin-bottom: 8px;
    padding-bottom: 8px;
    min-height: 64px;

    &__category {
      margin-right: 6px;
      height: 38px;
      display: flex;
      align-items: center;
      padding: 0 16px 0 16px;
      border-radius: 8px;
      background-color: #f7f8fa;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #858897;
      white-space: pre;
      cursor: pointer;

      &-active {
        background-color: #7677bc;
        color: white;
      }
    }
  }

  &__products {
    margin-left: -12px;
    padding-right: 16px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 445px) {
      margin: 0 -14px 0 -12px;
    }
  }
}
