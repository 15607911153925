.add-product-order {
  border-radius: 16px;
  position: relative;

  &__close {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  &__header {
    padding: 36px 36px 0 36px;
    display: flex;
    align-items: center;

    @media (max-width: 445px) {
      padding: 24px 16px 0 16px;
      flex-wrap: wrap;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: 445px) {
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &__content {
    padding: 0 36px 0 36px;
    height: 600px;
    display: flex;
    flex-direction: column;

    @media (max-width: 445px) {
      padding: 0 16px 0 16px;
    }
  }

  &__tabs {
    margin-left: 24px;
    padding: 4px 7px 4px 7px;
    height: 52px;
    border-radius: 8px;
    border: 1px solid #eaedf2;
    display: flex;
    width: 332px;

    @media (max-width: 445px) {
      margin-left: 0;
      margin-top: 24px;
      width: 100%;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      cursor: pointer;
      color: #858897;
      border-radius: 8px;

      @media (max-width: 445px) {
        width: 150px;
        flex: 1;
      }

      &-active {
        background-color: #7677bc;
        color: white;
      }
    }
  }

  &__search {
    margin-top: 24px;
    margin-bottom: 24px;

    @media (max-width: 445px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__best-products {
    padding-right: 8px;
    padding-bottom: 24px;
    overflow: auto;

    @media (max-width: 445px) {
      padding-right: 0;
      margin-right: -14px;
    }

    &__divider {
      margin: 20px 0 20px 0;
      background-color: #eef1f6;
      height: 1px;
    }
  }

  &__sellers {
    margin-left: -12px;
    padding-right: 12px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 445px) {
      padding-right: 0;
      margin-right: -14px;
    }

    &-seller {
      flex: 1;
      min-width: 396px;
      max-width: calc(50% - 24px);
      margin: 12px;

      @media (max-width: 445px) {
        min-width: auto;
        max-width: 100%;
      }
    }

    &-categories {
      display: flex;
      align-items: center;
      overflow: auto;
      padding-bottom: 8px;
      margin-bottom: 8px;
      min-height: 64px;

      &__category {
        margin-right: 6px;
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 16px 0 16px;
        border-radius: 8px;
        background-color: #f7f8fa;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: #858897;
        white-space: pre;
        cursor: pointer;

        &-active {
          background-color: #7677bc;
          color: white;
        }
      }
    }

    &-empty {
      height: 424px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }

      &__text {
        margin-top: 8px;
        width: 340px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #868897;
      }
    }
  }

  &__products {
    margin-left: -12px;
    padding-right: 12px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    &-empty {
      height: 424px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }

      &__text {
        margin-top: 8px;
        width: 340px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #868897;
      }
    }
  }

  &__seller-back {
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #858897;
    cursor: pointer;
  }

  &__added {
    flex: 1;
    margin-right: auto;

    &-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #858897;
    }

    &-info {
      margin-top: 4px;
      display: flex;
      align-items: center;
    }

    &-dot {
      margin-left: 8px;
      margin-right: 8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #bec2cd;
    }

    &-value {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 445px) {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &__search {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__best-products {
    padding-right: 24px;
    height: 424px;
    overflow: auto;
  }

  &__actions {
    padding: 16px 24px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: white;
    box-shadow: 0 -4px 24px rgba(0, 1, 23, 0.1);

    @media (max-width: 445px) {
      padding: 12px 16px 12px 16px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-btn {
      margin-left: 24px;
      width: 238px;

      @media (max-width: 445px) {
        margin-top: 16px;
        width: 46%;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
}
