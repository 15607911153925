.moderate-product__header {
  &-status {
    margin: -14px -14px 28px -14px;
    padding: 16px;
    background-color: rgba(216, 85, 63, 0.03);
    border: 1px solid #d8553f;
    border-radius: 16px;

    &__label {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &__text {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &-photo {
    position: relative;

    &-status {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 8px 0 8px;
      border-radius: 8px;
      border: 2px solid white;
      position: absolute;
      top: -18px;
      left: -18px;

      &__text {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: white;
      }
    }

    &-image-container {
      margin-right: 20px;
      height: 122px;
    }

    &-image {
      width: 138px;
      height: 112px;
      border-radius: 12px;
      object-fit: cover;
    }
  }

  &-date {
    font-size: 14px;
    color: #a0a3b5;
  }

  &-name {
    min-height: 56px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
  }

  &-country {
    margin-top: 12px;
    font-size: 16px;
    color: #a0a3b5;
  }

  &-value {
    display: inline-flex;
  }

  &-changed {
    margin-left: -12px;
    padding: 4px 12px 4px 12px;
    background-color: #fff4dd;
    border-radius: 12px;
  }
}
