.sellers-market {
  margin-bottom: 8px;

  &__type {
    margin-left: 16px;
  }

  &__title {
    display: flex;
    align-items: center;
    min-height: 72px;
    border-bottom: 1px solid rgba(133, 133, 133, 0.3);

    &:hover {
      cursor: pointer;
    }

    &-text {
      font-size: 28px;
      font-weight: bold;
      line-height: 1.15;

      @media (max-width: 445px) {
        font-size: 20px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(133, 133, 133, 0.3);
    max-height: 1000px;
    overflow: auto;

    &-seller {
      flex: 1;
      min-width: 396px;
      max-width: calc(50% - 24px);
      margin: 12px;
    }
  }
}
