.button-cmp {
  min-width: 222px;
  padding: 0 24px 0 24px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7677bc;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 445px) {
    min-width: auto;
  }

  &-outline {
    color: #7677bc;
    border: 2px solid #7677bc;
    border-radius: 8px;
    background-color: white;
  }

  &-secondary {
    box-shadow: inset 0 0 0 2px #7677bc;
    background-color: #ffffff;
    color: #7677bc;

    &:hover {
      background-color: #f6f5f5;
    }

    &:active {
      background-color: #f1f0f0;
    }
  }

  &-primary {
    &:hover {
      background-color: #6a6ba8;
    }

    &:active {
      background-color: #5d5d93;
    }
  }
}
