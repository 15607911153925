.customer-item-wrapper {
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 445px) {
    display: inline-flex;
  }

  &:hover {
    background-color: #f5f7fb;
  }

  &__selected {
    background-color: #eaedf2;

    .order-item__edit-btn {
      visibility: visible;
    }
  }
}

.customer-item {
  display: flex;
  min-height: 64px;
  margin-top: 6px;
  padding: 12px 12px 12px 12px;
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 445px) {
    display: inline-flex;
    padding: 12px 8px 12px 8px;
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

    @media (max-width: 445px) {
      font-size: 12px;
      line-height: 16px;
    }

    &-error {
      color: #d8553f;
      font-size: 14px;
      font-weight: 400;
      width: 100%;

      @media (max-width: 445px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__subvalue {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #a4aabf;
  }

  &__num {
    flex-basis: 5%;
    min-width: 40px;
  }

  &__phone-name {
    flex-basis: 28%;
    min-width: 160px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 445px) {
      min-width: 145px;
    }

    &-value {
      margin-right: 8px;
    }

    &-sep {
      display: inline;
      color: #a0a3b5;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &__last-order {
    flex-basis: 16%;
    min-width: 125px;

    @media (max-width: 445px) {
      min-width: 90px;
    }
  }

  &__first-order {
    flex-basis: 13%;
    min-width: 110px;

    @media (max-width: 445px) {
      min-width: 90px;
    }
  }

  &__register-date {
    flex-basis: 13%;
    min-width: 110px;

    @media (max-width: 445px) {
      min-width: 90px;
    }
  }

  &__orders {
    flex-basis: 10%;
    min-width: 80px;

    @media (max-width: 445px) {
      min-width: 80px;
    }
  }

  &__type {
    flex-basis: 15%;
    min-width: 100px;
    padding-right: 0;
  }
}
