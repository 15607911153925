.app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

input[type='color'] {
  min-height: 50px;
}
