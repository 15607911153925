.customer__vertical-divider {
  margin: 0 18px 0 18px;
  background-color: #e7eaef;
  width: 1px;

  @media (max-width: 445px) {
    margin: 0 10px 0 10px;
  }
}

.customer__divider {
  margin: 24px 0 24px 0;
  background-color: #e7eaef;
  height: 1px;
}
