.moderate-product__seller {
  &-organization {
    display: flex;
    align-items: center;
  }

  &-common {
    &__icon {
      margin-left: 12px;
    }
  }

  &-location {
    display: flex;
    align-items: center;
  }
}
