.moderate-product {
  padding: 24px;
  max-height: calc(100% - 56px);
  overflow: auto;
  background-color: white;
  border-radius: 16px 0 16px 16px;

  @media (max-width: 445px) {
    padding: 22px 16px 16px 22px;
  }

  &__padding {
    margin-top: 48px;
  }

  &-actions-container {
    display: flex;
    justify-content: flex-end;
  }

  &-actions {
    display: inline-flex;
    padding: 8px 8px 0 8px;
    background-color: white;
    border-radius: 16px 16px 0 0;
  }
}
