.signup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__message-error {
    text-align: left;
  }

  &__message {
    text-align: center;
  }

  &__field {
    margin-bottom: 12px;

    &-label {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
