.order-container {
  height: 100%;
  padding-bottom: 82px;
}

.order__back {
  max-width: 98px;
  margin: 12px;

  & > div {
    min-width: 98px;
  }
}
