.polygons {
  padding: 32px 40px;
  height: 100%;
  width: 100%;
  overflow: auto;

  &-areas {
    border-radius: 16px;
    background-color: white;
    padding-bottom: 36px;
    margin-top: 28px;

    &__price {
      &-icon {
        font-weight: 500;
        font-size: 16px;
        text-transform: unset;
      }
    }

    &__empty {
      padding: 36px 36px 0 36px;

      &-title {
        font-size: 20px;
        font-weight: 500;
      }

      &-text {
        margin-top: 12px;
        font-size: 16px;
      }

      &-btn {
        margin-top: 20px;

        button {
          height: 52px;
          background-color: #7677bc !important;
          border-radius: 8px !important;
        }
      }
    }

    &__edit {
      padding: 36px 36px 0 36px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
        border-bottom: 1px solid #d9dfe4;

        &__list {
          max-width: 960px;
          display: flex;
          align-items: center;
          overflow: auto;
        }

        &__tab {
          padding-left: 16px;
          padding-right: 16px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          margin-right: 24px;
          background-color: #f5f7fb;
          cursor: pointer;

          &-not-active {
            background-color: white;
            border: 1px solid #ebeef2;
          }

          &-color {
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }

          &-name {
            min-width: 100px;
            margin-left: 8px;
            margin-right: 8px;
            font-size: 16px;
            font-weight: 500;
          }

          &-remove {
            padding-left: 10px;
            margin-right: -6px;
            border-left: 1px solid #d9dfe4;

            &__icon {
              width: 28px;
              height: 28px;
              font-size: 18px !important;
              color: #bec2cd;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        &__add {
          max-width: 224px;
          min-width: 216px;
          margin-left: 24px;
          padding-left: 24px;
          padding-right: 24px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #7677bc;
          border-radius: 8px;
          color: white;
          cursor: pointer;

          &-plus {
            font-size: 24px;
            font-weight: 500;
          }

          &-text {
            margin-left: 8px;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      &__type {
        &-list {
          margin-top: 24px;
        }

        &-item {
          margin-top: 24px;
          display: flex;

          &__type {
            font-size: 16px;

            &-label {
              font-weight: 500;
            }

            &-value {
              display: flex !important;
              align-items: center;
              margin-top: 10px;
              border-radius: 8px;
              border: 1px solid #eaedf2;
              padding: 14px;
              height: 52px;
              min-width: 292px;

              & > div {
                flex: 1 !important;
              }
            }
          }

          &__price {
            margin-left: 24px;
            font-size: 16px;

            &-label {
              font-weight: 500;
            }

            &-value {
              margin-top: 10px;
              height: 52px;
              width: 156px;
            }
          }

          &__remove {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
            margin-left: 16px;
            cursor: pointer;

            &-icon {
              font-size: 20px !important;
              color: #bec2cd;
            }
          }

          &__add {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            color: #7677bc;
            cursor: pointer;
          }
        }

        &__min {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-top: 32px;

          &-label {
            font-weight: 500;
          }

          &-check {
            display: flex;
            margin-left: 16px;
            padding: 4px;
            border-radius: 8px;
            background-color: #f5f7fb;

            &-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 44px;
              width: 56px;
              border-radius: 8px;
              color: #777e96;
              cursor: pointer;

              &-active {
                background-color: #7677bc;
                color: white;
              }
            }
          }

          &-value {
            margin-left: 24px;
            height: 52px;
            width: 156px;
          }
        }
      }
    }

    &__area {
      width: 444px;
      min-width: 444px;
      border-radius: 16px;
      margin-right: 24px;
      padding: 16px 16px 24px 24px;
      background-color: white;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

      &-list {
        display: flex;
        overflow: auto;
        max-width: 100%;
        width: 100%;
        padding: 36px 36px 32px 36px;
      }

      &__header {
        display: flex;
        align-items: center;

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &-icon {
          color: #a0a3b5;
          margin-left: 8px !important;
        }

        &-color {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        &-name {
          margin-left: 8px;
          font-size: 20px;
          font-weight: 500;
          margin-right: 12px;
        }

        &-action {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f5f7fb;
          border-radius: 8px;
          font-weight: 500;
          font-size: 24px;
          cursor: pointer;

          &-icon {
            width: 24px;
            height: 24px;
            margin: 0 !important;
            font-size: 16px !important;
          }

          &__remove {
            min-width: 92px;
            color: #d8553f;
            cursor: pointer;
          }
        }
      }

      &__type-item {
        display: flex;
        justify-content: space-between;
        margin: 16px 8px 0 20px;
        padding-right: 20px;
        padding-bottom: 22px;
        border-bottom: 1px solid #d9dfe4;
      }

      &__type,
      &__price {
        font-size: 16px;
        font-weight: 400;

        &-label {
          color: #1a1e32;
          height: 24px;
          opacity: 0.5;
        }

        &-value {
          height: 24px;
          margin-top: 2px;
        }
      }

      &__min {
        display: flex;
        align-items: center;
        margin: 16px 8px 0 20px;
        font-size: 16px;
        font-weight: 400;

        &-label {
          color: #1a1e32;
          height: 24px;
          opacity: 0.5;
        }

        &-value {
          height: 24px;
          margin-left: 4px;
        }
      }
    }
  }

  &-map {
    margin: 24px 36px 0 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    &__actions {
      position: absolute;
      left: 16px;
      top: 24px;
      background-color: white;
      border-radius: 16px;
      padding: 6px 16px 16px 16px;

      &-btn {
        margin-top: 10px;
        border-radius: 6px;
        padding: 8px;
        font-size: 16px;
        font-weight: 500;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
        border: 1px solid white;
        cursor: pointer;

        &:hover {
          border: 1px solid #7677bc;
          color: #7677bc;
        }
      }
    }

    &__legend {
      position: absolute;
      left: 16px;
      bottom: 24px;
      background-color: white;
      border-radius: 16px;
      padding: 6px 16px 16px 16px;

      &__item {
        display: flex;
        align-items: center;
        height: 24px;
        margin-top: 10px;

        &-color {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }

        &-name {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  &-modal__area-hidden {
    max-width: 528px;
    border-radius: 16px !important;
    padding: 8px;

    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 16px;
    }

    &__type {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;
      cursor: pointer;

      &-icon {
        color: #a0a3b5;

        &-active {
          color: #d8553f;
        }
      }

      &-check {
        font-size: 24px;
      }

      &-label {
        font-size: 16px;
        opacity: 0.6;
        margin-left: 10px;
      }
    }

    &__actions {
      margin-top: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__btn {
      min-width: 220px;
      margin-left: 0 !important;

      &-disable {
        opacity: 0.3;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 32px 36px 0 36px;
  }

  &__btn {
    width: 216px;
    height: 52px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7677bc;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;

    &-outline {
      color: #7677bc;
      border: 2px solid #7677bc;
      border-radius: 8px;
      background-color: white;
    }
  }
}
