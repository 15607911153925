.order-assign {
  padding: 32px;

  @media (max-width: 445px) {
    padding: 16px 16px 8px 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 445px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__order {
    margin-top: 16px;
    margin-bottom: 28px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 24px rgba(9, 29, 52, 0.12);

    &-title {
      color: #777e96;
      font-size: 16px;
      line-height: 24px;
    }

    &-info {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #1a1e32;

      &__num {
      }

      &__address {
        max-width: 60%;
      }

      &__distance {
        margin-left: 6px;
        display: inline-flex;
        color: #a4aabf;
      }

      &__dot {
        margin-left: 8px;
        margin-right: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #a4aabf;
      }
    }
  }

  &__courier {
    &-title {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      color: #1a1e32;
    }

    &-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #1d1d1b;

      &__busy {
        font-size: 14px;
      }

      &__dot {
        margin-left: 8px;
        margin-right: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #a4aabf;
      }
    }

    &-dropdown {
      width: 100%;

      &__menu {
        width: 100%;
      }
    }

    &-elem {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid #eaedf2;
    }
  }

  &__actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
