.textarea-field {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  &__label {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    padding: 9px 0 6px 16px;
    color: #858897;
    font-size: 12px;
    font-weight: 400;
    background: white;
  }

  &__input {
    width: 100%;
    height: 100%;

    &-with-label {
      textarea {
        padding-top: 26px !important;
        font-weight: 700 !important;
      }
    }

    textarea {
      width: 100% !important;
      height: 100% !important;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
