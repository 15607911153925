.edit-tag {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_field-container {
    flex: 1;
  }

  &_field {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin: 20px 0 20px 0;
    min-width: 390px;

    &__label {
      max-width: 180px;
      width: 90px;
      margin-right: 16px;

      @media (max-width: 445px) {
        max-width: 110px;
        width: 110px;
        min-width: 110px;
      }
    }

    &__value {
      flex: 1;
      margin-left: 20px;

      &-name {
        max-width: 300px;
      }

      &-color {
        max-width: 100px;
      }

      &-priority {
        max-width: 100px;
      }

      &-image {
        margin-left: 20px;
      }
    }
  }
}
