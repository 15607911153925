.modal-delivery {
  padding: 32px;
  border-radius: 16px;

  @media (max-width: 445px) {
    padding: 16px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 445px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__address {
    margin-top: 24px;
    width: 100%;

    &-field {
      height: 52px;
      border-radius: 8px;

      input {
        height: 52px;
        border-radius: 8px !important;
      }
    }
  }

  &__time {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;

    &-date {
      flex: 1;
    }

    &-slot {
      flex: 1;

      @media (max-width: 445px) {
        margin-top: 24px;
      }
    }
  }

  &__label {
    max-width: 180px;
    width: 140px;
    min-width: 140px;
    margin-right: 16px;
    color: #a0aeb5;
    font-style: italic;

    @media (max-width: 445px) {
      max-width: 110px;
      width: 110px;
      min-width: 110px;
    }
  }

  &__value {
    flex: 1;
  }

  &__divider {
    margin: 24px 0 24px 0;
    height: 1px;
    background-color: #d9dfe4;
  }

  &__actions {
    display: flex;
  }

  &__btn {
    margin-left: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7677bc;
    height: 48px;
    border-radius: 6px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
  }

  &__btn-outline {
    margin-left: 0;
    margin-right: 12px;
    border: 2px solid #7677bc;
    color: #7677bc;
    background-color: white;
  }
}
