.order__seller {
  &-name {
    &__icon {
      margin-left: 6px !important;
      color: #bec2cd !important;
    }
  }

  &-added {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    }

    &__time {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #858897;
    }
  }

  &-products {
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
    flex-wrap: wrap;
  }
}

.order__products {
  &-action {
    margin-top: 24px;
  }
}
