.products-container {
  padding: 32px 0 116px 32px;
  height: 100%;

  @media (max-width: 445px) {
    padding: 24px 0 20px 20px;
  }

  &_list {
    margin-top: 20px;
    padding: 24px 0 24px 8px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 445px) {
      margin-top: 8px;
    }
  }
}
