.stock-subcategory-edit {
  width: 100%;
  margin-bottom: 24px;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    flex: 1;
    margin-right: 16px;

    &-input {
      width: 100%;

      & > input {
        height: 52px !important;
        border-radius: 8px !important;
        border: 1px solid #eaedf2 !important;
      }
    }
  }

  &__position {
    width: 94px;
    height: 52px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #eaedf2;
    cursor: pointer;

    &-up,
    &-down {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #bec2cd;
      }

      &:hover {
        svg {
          fill: #7677bc;
        }
      }
    }

    &-divider {
      margin: 10px 8px 10px 8px;
      background-color: #eaedf2;
      width: 1px;
    }

    &-down {
      transform: rotate(180deg);
    }
  }

  &__visibility,
  &__remove {
    margin-left: 8px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #eaedf2;
    cursor: pointer;
  }

  &__visibility {
    &-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #858897;
    }

    &-active {
      svg {
        fill: #bec2cd;
      }

      &:hover {
        svg {
          fill: #7677bc;
        }
      }
    }
  }

  &__remove {
    svg {
      stroke: #bec2cd;
    }

    &:hover {
      svg {
        stroke: #eb5757;
      }
    }
  }
}
