.change-tag {
  &__field {
    margin-top: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-unit {
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }

    &-label {
      max-width: 200px;
      width: 200px;
      margin-right: 16px;

      @media (max-width: 445px) {
        max-width: 100%;
        width: 100%;
      }
    }

    &-value {
      flex: 1;
      min-width: 150px;
      max-width: 350px;
    }

    &-link {
      display: flex;
      align-items: center;
      margin-top: 6px;
      margin-bottom: 8px;

      &__icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    &-error {
      margin-top: 12px;
      color: #9f3a38;
    }

    &-image__wrapper {
      padding: 3px;
      border: 3px solid #0d71bb;
      position: relative;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &__actions {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        &-btn {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-left: 8px;
          margin-right: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: rgba(133, 133, 133, 0.45);

          &-icon {
            width: 36px;
            height: 36px;
            margin: 0 !important;
            color: white;
          }

          &:hover {
            cursor: pointer;
            font-size: 1.25em !important;
          }
        }
      }
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.42);
    }

    &-image-container {
      height: 300px;
      width: 400px;
      overflow: hidden;
      position: relative;

      @media (max-width: 445px) {
        height: 200px;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
