.customers {
  height: 100%;
  padding: 26px 0 32px 32px;
  background-color: #f5f7fb;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 32px;

    @media (max-width: 445px) {
      padding-right: 16px;
    }

    &__total {
      display: flex;
      align-items: center;

      &-value {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-label {
        color: #858897;
        padding-right: 4px;
      }

      &-dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-left: 8px;
        margin-right: 8px;
        background-color: #bec2cd;
      }
    }
  }

  @media (max-width: 445px) {
    padding: 16px 0 16px 16px;
  }

  &-container {
    display: flex;
    height: 100%;

    &__block {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (max-width: 445px) {
        overflow: auto;
      }
    }
  }

  &-filter__row {
    margin-bottom: 10px;

    &-label {
      margin-right: 12px;
      font-size: 16px;
      color: #a0aeb5;
      font-style: italic;
    }

    &-value {
      font-size: 17px;
      font-weight: 700;
    }
  }

  &-header {
    margin: 0 16px 12px 0;
    padding: 16px 8px 0 8px;
    background-color: white;
    border-radius: 16px;

    @media (min-width: 445px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (max-width: 445px) {
      //min-height: 128px;
      margin: 8px 12px 8px 0;
      padding: 12px 8px 0 8px;
    }

    &__inputs,
    &__btns {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__inputs {
      flex: 1;
    }

    &__btns {
      display: flex;
    }

    &__search,
    &__city,
    &__market,
    &__report,
    &__filter {
      margin: 0 12px 16px 12px;

      @media (max-width: 445px) {
        margin: 0 8px 12px 8px;
      }
    }

    &__search {
      min-width: 252px;
      flex: 2;

      &-label {
        background-color: #7677bc !important;
        border-radius: 0 10px 10px 0 !important;
      }

      input {
        border-radius: 10px 0 0 10px !important;
      }

      &-icon {
        color: white !important;
        margin: 0 6px 0 6px !important;
      }
    }

    &__city {
      flex: 1;
      min-width: 228px;
    }

    &__search,
    &__city {
      height: 52px;

      @media (max-width: 445px) {
        height: 46px;
      }

      &-dropdown,
      &-input {
        width: 100%;
        height: 52px;
        display: flex !important;
        align-items: center;
        border-radius: 8px !important;

        @media (max-width: 445px) {
          height: 46px;
        }

        input {
          height: 52px;

          @media (max-width: 445px) {
            height: 46px;
          }
        }

        i {
          height: 52px !important;
          display: flex !important;
          align-items: center !important;

          @media (max-width: 445px) {
            height: 46px;
          }
        }
      }

      &-input {
        & > div {
          height: 52px;
          display: flex !important;
          align-items: center !important;
          line-height: 30px;

          @media (max-width: 445px) {
            height: 46px;
          }
        }
      }
    }

    &__tabs {
      margin: 0 12px 16px 12px;
      padding: 4px 7px 4px 7px;
      height: 52px;
      border-radius: 8px;
      border: 1px solid #eaedf2;
      display: flex;
      width: 184px;

      @media (max-width: 445px) {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 2px;
        width: 100%;
      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86px;
        cursor: pointer;
        color: #858897;
        border-radius: 8px;

        @media (max-width: 445px) {
          flex: 1;
        }

        &-active {
          background-color: #7677bc;
          color: white;
        }
      }
    }

    &__report,
    &__filter {
      height: 52px;
      min-width: 160px;
      width: 177px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #7677bc;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      color: #7677bc;

      @media (max-width: 445px) {
        height: 46px;
        flex: 1 1;
        min-width: 124px;
        width: 100%;
        font-size: 13px;
      }

      &-badge {
        position: absolute;
        top: -4px;
        right: -3px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #7677bc;
      }

      &-content {
        margin-right: 3px;
        position: relative;
        width: 24px;
        height: 24px;
      }

      &-icon {
        font-size: 16px !important;
      }

      &-dropdown {
        height: 52px;
        width: 100%;
        padding: 0 18px 0 18px;
        display: flex !important;
        align-items: center !important;

        & > div {
          width: 100%;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(#7677bc, 0.1);
      }
    }
  }

  &-list {
    padding-bottom: 32px;
    padding-right: 16px;
    overflow: auto;
    height: 100%;
    margin-top: 12px;
    margin-bottom: 28px;

    @media (max-width: 445px) {
      margin-top: 16px;
    }

    &__placeholder {
      &-item {
        width: 100%;
      }
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 826px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__divider {
      font-size: 18px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a0aeb5;

      @media (max-width: 445px) {
        font-size: 16px;
        height: 44px;
      }
    }

    &__header {
      display: flex;
      padding: 11px 24px 10px 24px;

      &-value {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #a4aabf;
        flex: 1;

        &__icon {
          width: 16px;
          height: 16px;

          &-down {
            transform: rotate(180deg);
          }
        }

        &__filter {
          cursor: pointer;

          &-active {
            color: #7677bc;
          }
        }
      }
    }

    &__divider {
      background-color: #eef1f6;
      height: 1px;
      margin-left: 24px;
      margin-right: 24px;
    }

    &__rows {
      background-color: white;
      border-radius: 12px;
      padding: 1px 6px 6px 6px;

      @media (max-width: 445px) {
        display: inline-flex;
        flex-direction: column;
      }
    }

    &__num {
      flex-basis: 5%;
      min-width: 40px;
    }

    &__phone-name {
      flex-basis: 28%;
      min-width: 160px;

      @media (max-width: 445px) {
        min-width: 150px;
      }

      &-value {
        margin-right: 8px;
      }
    }

    &__last-order {
      flex-basis: 16%;
      min-width: 125px;

      @media (max-width: 445px) {
        min-width: 90px;
      }
    }

    &__first-order {
      flex-basis: 13%;
      min-width: 110px;

      @media (max-width: 445px) {
        min-width: 90px;
      }
    }

    &__register-date {
      flex-basis: 13%;
      min-width: 110px;

      @media (max-width: 445px) {
        min-width: 90px;
      }
    }

    &__orders {
      flex-basis: 10%;
      min-width: 80px;

      @media (max-width: 445px) {
        min-width: 90px;
      }
    }

    &__type {
      flex-basis: 15%;
      min-width: 100px;

      @media (max-width: 445px) {
        min-width: 95px;
      }
    }
  }

  &-customer {
    width: 460px;
    min-width: 460px;
    margin-right: 32px;
    margin-bottom: 40px;
    background-color: white;
    border-radius: 16px;
  }
}
