.orders {
  height: 100%;
  padding: 26px 0 32px 32px;
  background-color: #f5f7fb;

  @media (max-width: 445px) {
    padding: 16px 0 16px 16px;
  }

  &-container {
    display: flex;
    height: 100%;

    &__block {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (max-width: 445px) {
        overflow: auto;
      }
    }
  }

  &-tabs {
    display: flex;
    margin-bottom: 24px;

    @media (max-width: 445px) {
      margin-top: 6px;
      margin-bottom: 6px;
    }

    &__item {
      min-height: 32px;
      margin-right: 32px;
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
      color: #a4aabf;
      cursor: pointer;

      @media (max-width: 445px) {
        min-height: 28px;
        font-size: 22px;
        line-height: 24px;
        margin-right: 24px;
      }

      &-active {
        color: #1a1e32;
      }
    }
  }

  &-day-tabs {
    display: flex;
    margin-top: 12px;

    @media (max-width: 445px) {
      margin-top: 6px;
    }

    &__item {
      min-height: 26px;
      margin-right: 32px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: #a4aabf;
      cursor: pointer;

      @media (max-width: 445px) {
        min-height: 24px;
        font-size: 18px;
        line-height: 22px;
        margin-right: 24px;
      }

      &-active {
        color: #1a1e32;
      }
    }
  }

  &-filter__row {
    margin-bottom: 10px;

    &-label {
      margin-right: 12px;
      font-size: 16px;
      color: #a0aeb5;
      font-style: italic;
    }

    &-value {
      font-size: 17px;
      font-weight: 700;
    }
  }

  &-header {
    margin: 0 16px 12px 0;
    padding: 16px 8px 0 8px;
    background-color: white;
    border-radius: 16px;

    @media (min-width: 445px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (max-width: 445px) {
      min-height: 128px;
      margin: 8px 12px 8px 0;
      padding: 12px 8px 0 8px;
    }

    &__inputs,
    &__btns {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__inputs {
      flex: 1;
    }

    &__btns {
      display: flex;
      flex-direction: column;

      @media (min-width: 1200px) {
        flex-direction: row;
      }

      @media (max-width: 900px) {
        flex-direction: column;
      }

      @media (max-width: 445px) {
        flex-direction: row;
        width: 100%;
      }
    }

    &__search,
    &__city,
    &__market,
    &__filter,
    &__report {
      margin: 0 12px 16px 12px;

      @media (max-width: 445px) {
        margin: 0 8px 12px 8px;
      }
    }

    &__search {
      min-width: 252px;
      flex: 2;

      &-label {
        background-color: #7677bc !important;
        border-radius: 0 10px 10px 0 !important;
      }

      input {
        border-radius: 10px 0 0 10px !important;
      }

      &-icon {
        color: white !important;
        margin: 0 6px 0 6px !important;
      }
    }

    &__city,
    &__market {
      flex: 1;
      max-width: 500px;
    }

    &__search,
    &__city,
    &__market {
      height: 52px;

      @media (max-width: 445px) {
        height: 46px;
      }

      &-dropdown,
      &-input {
        width: 100%;
        height: 52px;
        display: flex !important;
        align-items: center;
        border-radius: 8px !important;

        @media (max-width: 445px) {
          height: 46px;
        }

        input {
          height: 52px;

          @media (max-width: 445px) {
            height: 46px;
          }
        }

        i {
          height: 52px !important;
          display: flex !important;
          align-items: center !important;

          @media (max-width: 445px) {
            height: 46px;
          }
        }
      }

      &-input {
        & > div {
          height: 52px;
          display: flex !important;
          align-items: center !important;
          line-height: 30px;

          @media (max-width: 445px) {
            height: 46px;
          }
        }
      }
    }

    &__filter,
    &__report {
      height: 52px;
      min-width: 160px;
      width: 177px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #7677bc;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      color: #7677bc;

      @media (max-width: 445px) {
        height: 46px;
        flex: 1 1;
        min-width: 124px;
        width: 100%;
        font-size: 13px;
      }

      &-badge {
        position: absolute;
        top: -4px;
        right: -3px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #7677bc;
      }

      &-content {
        margin-right: 3px;
        position: relative;
        width: 24px;
        height: 24px;
      }

      &-icon {
        font-size: 16px !important;
      }

      &-dropdown {
        height: 52px;
        width: 100%;
        padding: 0 18px 0 18px;
        display: flex !important;
        align-items: center !important;

        & > div {
          width: 100%;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(#7677bc, 0.1);
      }
    }
  }

  &-list {
    padding-bottom: 32px;
    padding-right: 16px;
    overflow: auto;
    height: 100%;
    margin-top: 12px;
    margin-bottom: 28px;

    @media (max-width: 445px) {
      margin-top: 16px;
    }

    &__placeholder {
      &-item {
        width: 100%;
      }
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 826px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__divider {
      font-size: 18px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a0aeb5;

      @media (max-width: 445px) {
        font-size: 16px;
        height: 44px;
      }
    }

    &__date {
      margin-top: 24px;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;

      @media (max-width: 445px) {
        margin-top: 12px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &-order {
    width: 460px;
    min-width: 460px;
    margin-right: 32px;
    margin-bottom: 40px;
    background-color: white;
    border-radius: 16px;
  }
}
