.picker-item {
  font-size: 16px;
  display: flex;
  align-items: center;

  &-value {
    width: 100%;

    & > div {
      width: 100%;
      height: 52px;
      display: flex !important;
      align-items: center;
      border-radius: 8px !important;
    }

    input {
      height: 52px;
    }

    i {
      height: 52px !important;
      display: flex !important;
      align-items: center !important;
    }
  }

  &-delivery {
    font-size: 18px;
    margin-bottom: 18px;
  }

  &-title {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 700;
  }

  & .ui.selection.active.dropdown {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-color: #eaedf2 !important;
    box-shadow: none !important;
  }

  & .menu {
    margin-top: 5px !important;
    border-radius: 12px !important;
    border: 0 !important;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12) !important;
    max-height: 296px !important;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 7px;
      margin-bottom: 7px;
      border-radius: 5px;
    }
  }
}

.list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: color 0.2s;

  &__value {
    display: flex;
    flex-direction: row;
    font-size: 16px;

    &-price {
      margin-left: 5px;
      color: #a0a3b5;
      font-size: 16px;
    }
  }

  &:hover {
    color: #7677bc;
  }

  &__text {
    font-size: 16px;
  }

  &__price {
    font-weight: 500;
    font-size: 16px;
  }

  &__divider {
    display: flex;
    height: 1px;
    background-color: #d9dfe4;
    margin-right: 16px;
    margin-left: 16px;
  }
}
