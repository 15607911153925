.courier-shift {
  padding: 32px;

  @media (max-width: 445px) {
    padding: 16px 16px 8px 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;

    @media (max-width: 445px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__courier {
    display: flex;
    margin-top: 16px;
    margin-bottom: 28px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 24px rgba(9, 29, 52, 0.12);

    &-block {
      min-width: 216px;
      margin-right: 40px;

      &__num {
        margin-right: 2px;
        font-size: 16px;
        font-weight: 500;
        color: #777e96;
      }

      &__label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #777e96;
      }

      &__text {
        margin-top: 2px;
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
        color: #1a1e32;
      }
    }
  }

  &__slots {
    border-bottom: 1px solid #d9dfe4;

    &-title {
      margin-top: 24px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
    }

    &-list {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      width: 216px;
      display: flex;
      align-items: center;
      margin: 0 24px 24px 0;
      padding: 14px 16px 14px 16px;
      border: 1px solid #eaedf2;
      border-radius: 8px;
      cursor: pointer;

      &__field {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 4px;
        border: 2px solid #bec2cd;

        &-checked {
          background-color: #eb5757;
          border: none;
        }
      }

      &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &__actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-remove {
      display: flex;
      align-items: center;
      margin-right: auto;
      cursor: pointer;

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #858897;
      }
    }
  }
}
