.customer__orders {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    &__check {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &-label {
        color: #a0a3b5;
        padding-right: 6px;
      }
    }
  }

  &-item {
    margin-top: 16px;
    padding: 16px 20px 16px 20px;
    border-radius: 12px;
    box-shadow: 0 2px 16px rgba(0, 1, 23, 0.12);
    cursor: pointer;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-num {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &__show {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7677bc;
    cursor: pointer;
  }
}
