.customer__addresses {
  &-item {
    margin-top: 16px;
    padding-bottom: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #e7e8ea;
    }

    &__address {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__show {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7677bc;
    cursor: pointer;
  }
}
