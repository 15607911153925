.modal-product {
  padding: 36px;
  border-radius: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  &__form {
    margin-top: 32px;
    display: flex;

    &-block {
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
    }

    &-fields {
      flex: 1;
      margin-left: 24px;
    }

    &-field {
      display: flex;
      flex: 1;
    }
  }

  &__image {
    width: 120px;

    &-container {
      width: 120px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      svg {
        fill: #bec2cd;
      }

      &:hover {
        svg {
          fill: #7677bc;
        }
      }
    }

    &-img {
      width: 120px;
      height: 90px;
      border-radius: 8px;
      object-fit: cover;
    }

    &-empty {
      border-radius: 8px;
      border: 1px solid #eaedf2;
    }

    &-placeholder {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.42);
      border-radius: 8px;
    }

    &__actions {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-btn {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eaedf2;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }

  &__copy-btn {
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eaedf2;
    border-radius: 8px;
    cursor: pointer;
  }

  &__about {
    min-width: 198px;
    margin-left: 24px;
  }

  &__description {
    max-width: 238px;
    margin-right: 24px;
  }

  &__country-tags {
    flex: 1;
    display: flex;
  }

  &__country {
    min-height: 56px;
    max-width: 198px;
    margin-bottom: 24px;
    margin-left: 24px;
  }

  &__tags {
    min-height: 56px;
    flex: 1;
  }

  &__stock-category {
    min-height: 56px;

    &-title {
      width: 100%;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__stock-subcategory {
    min-height: 56px;
    margin-left: 24px;
  }

  &__category {
    min-height: 56px;

    &-title {
      width: 100%;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__subcategory {
    height: 56px;
    margin-left: 24px;
  }

  &__vendor-code {
    height: 56px;
    margin-left: 24px;
  }

  &__rating {
    height: 56px;
    margin-left: 24px;
  }

  &__unit {
    height: 56px;
    flex: 1;
    max-width: 31.2%;
  }

  &__weight {
    height: 56px;
    flex: 1;
    max-width: 32%;
    margin-left: 24px;
  }

  &__icon-kg {
    font-weight: 500;
    font-size: 16px;
    text-transform: unset;
    margin-top: 6px;
  }

  &__step {
    height: 56px;
    flex: 1;
    max-width: 31.2%;
    margin-left: 24px;
  }

  &__divider {
    margin: 24px 0 24px 0;
    height: 1px;
    background-color: #d9dfe4;
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
      margin-left: 24px;
      width: 238px;
    }
  }

  &__price {
    flex: 2;
    max-width: 240px;
    margin-right: 20px;

    &-icon {
      font-weight: 500;
      font-size: 16px;
      text-transform: unset;
      margin-top: 6px;
    }
  }

  &__source-price {
    flex: 0.94;
    margin-left: 28px;
    display: flex;
    align-items: center;

    &__text {
      font-size: 16px;
      color: rgba(133, 133, 133, 0.85);
    }

    &__btn {
      max-width: 188px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-decoration: underline;
      color: #7677bc;
      cursor: pointer;
    }
  }

  &__links {
    display: flex;
    align-items: center;

    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
    }

    &-link {
      margin-left: 16px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;

      a {
        color: #6162be;
      }

      &__icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  &__field {
    margin-top: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-unit {
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }

    &-label {
      max-width: 200px;
      width: 200px;
      margin-right: 16px;

      @media (max-width: 445px) {
        max-width: 100%;
        width: 100%;
      }
    }

    &-value {
      flex: 1;
      min-width: 150px;
      max-width: 350px;
    }

    &-link {
      display: flex;
      align-items: center;
      margin-top: 6px;
      margin-bottom: 8px;

      &__icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    &-error {
      margin-top: 12px;
      color: #9f3a38;
    }

    &-image__wrapper {
      padding: 3px;
      border: 3px solid #0d71bb;
      position: relative;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &__actions {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        &-btn {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-left: 8px;
          margin-right: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: rgba(133, 133, 133, 0.45);

          &-icon {
            width: 36px;
            height: 36px;
            margin: 0 !important;
            color: white;
          }

          &:hover {
            cursor: pointer;
            font-size: 1.25em !important;
          }
        }
      }
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.42);
    }

    &-image-container {
      height: 300px;
      width: 400px;
      overflow: hidden;
      position: relative;

      @media (max-width: 445px) {
        height: 200px;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
