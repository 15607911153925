.input-field {
  position: relative;
  padding: 0 16px 0 16px;
  width: 100%;
  min-height: 52px;
  display: flex;
  align-items: center;
  border: 1px solid #eaedf2;
  border-radius: 8px;

  &__label {
    position: absolute;
    top: 9px;
    left: 16px;
    color: #858897;
    font-size: 12px;
    font-weight: 400;
  }

  &__input {
    max-height: 22px;
    width: 100%;

    input {
      font-size: 15px;
      line-height: 20px;
    }

    &-with-label {
      input {
        font-weight: 700 !important;
        margin-top: 9px !important;
      }
    }
  }

  &__error {
    border-color: #e0b4b4 !important;
    background-color: #fff6f6;

    input::placeholder {
      color: #9f3a38 !important;
    }
  }
}
