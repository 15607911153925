.analytics__block__period {
  &-chart {
    padding: 16px 24px 24px 24px;
    width: 100%;
    height: 100%;
  }

  &-total {
    margin-left: 24px;
  }
}
