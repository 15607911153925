.slots-monitoring {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  max-width: 1300px;

  &__content {
    flex-grow: 2;
    flex-shrink: 1;
  }

  &__filter {
    display: flex;

    &__field {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 8px;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-label {
        max-width: 160px;
        margin-right: 16px;
      }

      &-value {
        width: 300px;
        max-width: 90%;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    flex-grow: 1;
    flex-shrink: 1;

    &__item {
      width: 452px;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        &__title {
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
        }

        &__dot {
          position: relative;
          top: 2px;
          margin-left: 8px;
          margin-right: 8px;
          align-self: center;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #A4AABF;
        }

        &__date{
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #858897;
        }
      }

      &__content {
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
        color: #858897;
      }
    }
  }
}
