.slot-chart {
  position: relative;
  $chart-width: 190px;

  &__canvas {
    width: $chart-width;
    height: 98px;
  }

  &__content {
    width: $chart-width / 1.45;
    position: absolute;
    top: 40px;
    left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;

    &-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
    }

    &-desc {
      font-size: 12px;
      line-height: 14px;
      color: #858897;
    }
  }
}
