.chain-container {
  padding: 28px 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
}

.chain {
  border-radius: 16px;

  &__link-btn {
    position: absolute;
    right: 0;
    top: -82px;
    width: 240px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7677bc;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
  }

  &__groups {
    display: flex;
    flex-wrap: wrap;
  }

  &__products-container {
    border-radius: 16px;
    padding: 16px 32px 32px 32px;
    background-color: white;
  }

  &__back {
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #858897;
    cursor: pointer;
  }

  &__group {
    width: 100%;
    padding: 36px;
    margin-bottom: 28px;
    background-color: white;
    border-radius: 16px;

    &-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 48px;
      padding-bottom: 12px;
      border-bottom: 2px solid #e7e8ea;

      &__label {
        height: 36px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        background-color: #1a1e32;
        color: white;
        font-weight: 700;
        font-size: 18px;

        &-dot {
          margin-left: 8px;
          margin-right: 8px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: white;
          opacity: 50%;
        }

        &-visible {
          border-radius: 50%;
          height: 36px;
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: auto;
          margin-left: 8px;
          background-color: #f59e4d;
        }
      }

      &__actions {
        display: flex;
        align-items: center;

        &-btn {
          margin-left: 24px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &__icon {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }

          &__text {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }

    &-products {
      overflow: auto;
      width: 100%;
      padding-top: 8px;
      padding-right: 8px;
      display: flex;

      &__icon {
        align-self: center;
        margin-left: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        min-width: 64px;
        border-radius: 50%;
        background-color: #7677bc;
      }
    }
  }

  &__links {
    width: 100%;
    padding: 36px;
    margin-bottom: 28px;
    background-color: white;
    border-radius: 16px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
    }

    &-add {
      width: 240px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #7677bc;
      border-radius: 8px;
      color: white;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__link {
  }

  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 612px;
    height: 196px;
    padding: 36px;
    margin-bottom: 28px;
    background-color: white;
    border-radius: 16px;

    &-group {
      margin-right: 28px;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }

    &-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #777e96;
    }

    &-btn {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__header {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 445px) {
      padding-top: 8px;
      flex-wrap: wrap;
    }

    &-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;

      @media (max-width: 445px) {
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &-reset {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #858897;
      text-decoration: underline;
      cursor: pointer;

      @media (max-width: 445px) {

      }
    }
  }

  &__link-to-root {
    display: flex;

    &__category {
      display: flex;
      align-items: center;
      padding-top: 32px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e7e8ea;

      &-image {
        width: 52px;
        height: 52px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;

        &__empty {}
      }

      &-name {
        padding-left: 20px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    &__subcategory {
      margin-top: 20px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
    }

    &__root {
      width: 277px;

      &-product {
        margin-top: 16px;
        margin-left: -24px;

        &__label {
          padding-right: 12px;
          padding-left: 12px;
          position: absolute;
          left: 10px;
          top: 10px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          background-color: #5bc258;
          color: white;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 388px;
      left: 328px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: #7677bc;
    }

    &__products {
      width: 100%;
      padding: 52px;
      margin-left: 52px;
      margin-top: -52px;
      border-radius: 16px;
      background-color: #f7f8fa;
    }
  }

  &__content {
    padding-top: 32px;
    max-height: 1200px;
    display: flex;
    flex-direction: column;

    @media (max-width: 445px) {
      padding-top: 16px;
    }
  }

  &__best-products {
    padding-right: 8px;
    padding-bottom: 24px;
    overflow: auto;

    @media (max-width: 445px) {
      padding-right: 0;
      margin-right: -14px;
    }

    &__divider {
      margin: 20px 0 20px 0;
      background-color: #eef1f6;
      height: 1px;
    }
  }
}
