.order__payment-type {
  &__status {
    display: inline-flex;
    margin-left: 8px;
  }

  &__refund {
    color: black;
  }

  &__paid {
    color: #00c752;
  }

  &__not-paid {
    color: #d8553f;
  }
}
