.order-item-wrapper {
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 445px) {
    display: inline-flex;
  }

  &:hover {
    background-color: #f5f7fb;

    .order-item__edit-btn {
      visibility: visible;
    }
  }

  &__selected {
    background-color: #eaedf2;

    .order-item__edit-btn {
      visibility: visible;
    }
  }
}

.order-item {
  display: flex;
  margin-top: 6px;
  padding: 12px 12px 12px 12px;
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 445px) {
    display: inline-flex;
    padding: 12px 8px 12px 8px;
  }

  &__late {
    background-color: rgba(#eb5757, 0.1);
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

    @media (max-width: 445px) {
      font-size: 12px;
      line-height: 16px;
    }

    &-error {
      color: #d8553f;
      font-size: 14px;
      font-weight: 400;
      width: 100%;

      @media (max-width: 445px) {
        font-size: 12px;
        line-height: 16px;
      }
    }


    &-picked {
      color: #2F80ED;
    }
  }

  &__subvalue {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #a4aabf;
  }

  &__payment-status {
    width: 100%;
    line-height: 24px;
    color: #a0a3b5;

    &__status {
      display: inline-flex;
    }

    &__refund {
      color: black;
    }

    &__paid {
      color: #00c752;
    }

    &__not-paid {
      color: #d8553f;
    }
  }

  &__address {
    display: inline;

    &-value {
      display: inline;
      margin-right: 4px;
    }

    &-distance {
      display: inline;
      color: #a4aabf;
    }
  }

  &__edit-btn {
    margin-top: -8px;
    margin-left: -4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    visibility: hidden;

    &__icon {
      width: 16px !important;
      height: 16px !important;
      margin: 0 !important;
      color: #c4c6d3 !important;
    }
  }

  &__num {
    flex-basis: 12%;
    min-width: 100px;

    &-value {
      margin-right: 8px;
    }
  }

  &__address {
    flex-basis: 45%;
    padding-right: 12px;

    @media (max-width: 445px) {
      min-width: 145px;
    }
  }

  &__weight {
    flex-basis: 8%;
    min-width: 80px;

    @media (max-width: 445px) {
      min-width: 75px;
    }
  }

  &__courier {
    flex-basis: 13%;
    min-width: 130px;

    @media (max-width: 445px) {
      min-width: 110px;
    }
  }

  &__payment {
    flex-basis: 11%;
    min-width: 110px;

    @media (max-width: 445px) {
      min-width: 90px;
    }
  }

  &__status {
    flex-basis: 11%;
    min-width: 115px;
    padding-right: 0;

    @media (max-width: 445px) {
      min-width: 95px;
    }
  }
}
