.notifications {
  padding: 32px 0 32px 32px;
  height: 100%;

  @media (max-width: 445px) {
    padding: 24px 0 20px 20px;
  }

  &__list {
    margin-top: 20px;
    padding: 24px 0 24px 8px;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 445px) {
      margin-top: 8px;
    }

    &-item {
      position: relative;
      margin-top: 24px !important;
      margin-right: 24px !important;
      min-height: 96px;

      &__delete {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -22px;
        right: -22px;
        border-radius: 50% !important;
        color: #db2828;
        width: 44px;
        height: 44px;
        background-color: white;
        border: 1px solid #a0aeb5;
        z-index: 10;
      }
    }

    &-add {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 96px;

      &__text {
        font-size: 16px;
      }
    }
  }
}
