.dropdown-field {
  position: relative;
  width: 100%;
  min-height: 56px;

  &__label {
    z-index: 2;
    position: absolute;
    top: 6px;
    left: 14px;
    color: #858897;
    font-size: 12px;
    font-weight: 400;
  }

  &__icon {
    width: 24px;
    position: absolute;
    right: 10px;
    transform: rotate(180deg);
  }

  &__input {
    min-height: 56px;

    & > div {
      min-height: 56px;
      padding-top: 10px !important;
      padding-bottom: 8px !important;
      border: 1px solid #eaedf2 !important;
      border-radius: 8px !important;
    }

    &-single {
      min-height: 56px;

      input {
        min-height: 56px;
      }

      & > div {
        padding: 8px 8px 8px 12px !important;
        min-height: 56px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
      }
    }

    &-with-label {
      & > div :nth-child(2) {
        margin-top: 18px;
        font-size: 15px;
        font-weight: 500 !important;
        line-height: 20px;
        padding-right: 16px;
        max-width: 96%;
      }
    }

    &-single-with-label {
      input {
        padding-top: 25px !important;
        padding-left: 12px !important;
      }
    }

    &-multiple-with-label {
      & > div {
        padding-top: 24px !important;
      }
    }
  }
}
