.masked-input {
  width: 156px;
  height: 52px;
  border: 1px solid #eaedf2;
  border-radius: 8px;
  padding: 14px;
  font-size: 16px;
  line-height: 24px;

  &::placeholder {
    color: #a4aabf;
  }

  &-error {
    box-shadow: inset 0 0 0 2px #f32f33;
  }
}
