.catalog-container {
  padding: 28px 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
}

.catalog {
  border-radius: 16px;
  background-color: white;

  &__header {
    padding: 36px 36px 0 36px;
    display: flex;
    align-items: center;

    @media (max-width: 445px) {
      padding: 24px 16px 0 16px;
      flex-wrap: wrap;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: 445px) {
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &__tabs {
    margin-left: 24px;
    padding: 4px 7px 4px 7px;
    height: 52px;
    border-radius: 8px;
    border: 1px solid #eaedf2;
    display: flex;
    min-width: 332px;

    @media (max-width: 445px) {
      margin-left: 0;
      margin-top: 24px;
      width: 100%;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 160px;
      padding: 0 12px 0 12px;
      cursor: pointer;
      color: #858897;
      border-radius: 8px;

      @media (max-width: 445px) {
        width: 150px;
        flex: 1;
      }

      &-active {
        background-color: #7677bc;
        color: white;
      }
    }
  }

  &__search {
    margin-top: 24px;
    margin-bottom: 24px;

    @media (max-width: 445px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__content {
    padding: 0 36px 18px 36px;
    max-height: 1200px;
    display: flex;
    flex-direction: column;

    @media (max-width: 445px) {
      padding: 0 16px 0 16px;
    }
  }

  &__best-products {
    padding-right: 8px;
    padding-bottom: 24px;
    overflow: auto;

    @media (max-width: 445px) {
      padding-right: 0;
      margin-right: -14px;
    }

    &__divider {
      margin: 20px 0 20px 0;
      background-color: #eef1f6;
      height: 1px;
    }
  }

  &__sellers {
    margin-left: -12px;
    padding-right: 12px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 445px) {
      padding-right: 0;
      margin-right: -14px;
    }

    &-seller {
      flex: 1;
      min-width: 396px;
      max-width: calc(50% - 24px);
      margin: 12px;

      @media (max-width: 445px) {
        min-width: auto;
        max-width: 100%;
      }
    }

    &-categories {
      display: flex;
      align-items: center;
      overflow: auto;
      padding-bottom: 8px;
      margin-bottom: 8px;
      min-height: 64px;

      &__category {
        margin-right: 6px;
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 16px 0 16px;
        border-radius: 8px;
        background-color: #f7f8fa;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: #858897;
        white-space: pre;
        cursor: pointer;

        &-active {
          background-color: #7677bc;
          color: white;
        }
      }
    }

    &-empty {
      height: 424px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }

      &__text {
        margin-top: 8px;
        width: 340px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #868897;
      }
    }
  }

  &__products {
    margin-left: -12px;
    padding-right: 12px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    &-empty {
      height: 424px;

      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }

      &__text {
        margin-top: 8px;
        width: 340px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #868897;
      }
    }
  }

  &__seller-back {
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #858897;
    cursor: pointer;
  }

  &__report {
    margin-left: auto;
  }
}
