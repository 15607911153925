.slot {
  display: flex;
  align-items: center;
  min-width: 407px;
  margin-bottom: 16px;

  &__hyphen {
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 500;
    font-size: 16px;
  }

  &__index {
    font-weight: 500;
    font-size: 16px;
    min-width: 15px;
    text-align: center;
    margin-right: 16px;

    &-default {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f3f5f9;
      border-radius: 6px;
      width: 28px;
      height: 28px;
      margin-right: 16px;
      color: #1a1e32;
      font-weight: bold;
      font-size: 12px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__icon {
    width: 17px;
    height: 17px;
    margin-left: 22px;
    cursor: pointer;
    user-select: none;

    svg {
      transition: stroke 0.2s;
      stroke: #bec2cd;
    }

    &:hover {
      svg {
        stroke: #db2828;
      }
    }

    &:active {
      svg {
        stroke: #ab1d1d;
      }
    }
  }
}
