.analytics {
  padding: 32px 0 32px 0;
  height: 100%;
  background-color: #f5f7fb;

  @media (max-width: 445px) {
    padding: 24px 0 20px 0;
  }

  &-title {
    margin-left: 28px;
    margin-bottom: 32px;
  }

  &-header__wrapper {
    margin-left: 28px;

    @media (max-width: 445px) {
      overflow: auto;
      margin-right: 28px;
    }
  }

  &-header {
    display: inline-flex;
    align-items: center;
    padding: 24px 20px 24px 20px;
    border-radius: 16px;
    background-color: white;

    &__btn {}
  }

  &-filter__period {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 384px;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 18px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1d1d1b;

      &__sub {
        display: flex;
        align-items: center;

        &-item {
          margin-left: 14px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #868897;
          cursor: pointer;
        }

        &-item-active {
          color: #1d1d1b;
        }
      }
    }

    &-label {
      margin-right: 12px;
      font-size: 16px;
      color: #a0aeb5;
      font-style: italic;
    }

    &-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
    }

    &-divider {
      width: 14px;
      height: 1px;
      background-color: black;
    }
  }

  &-filter__field {
    &-value {
      input {
        border-radius: 8px !important;
      }
    }
  }

  &-filter__divider-vertical {
    margin-left: 24px;
    margin-right: 24px;
    width: 1px;
    height: 76px;
    background-color: #dededf;
  }

  &-filter__types {
    display: flex;
    align-items: center;
  }

  &-filter__users {
    max-width: 156px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-title {
      width: 100%;
      margin-bottom: 18px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1d1d1b;
    }

    &-type {
      margin-right: 24px;
    }
  }

  &-filter__orders {
    max-width: 288px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 445px) {
      margin-top: 16px;
    }

    &-title {
      width: 100%;
      margin-bottom: 18px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1d1d1b;
    }

    &-type {
      margin-right: 24px;
    }
  }

  &-container {
    overflow: auto;
    height: calc(100% - 198px);
    margin-top: 32px;

    @media (max-width: 445px) {
      height: calc(100% - 242px);
    }
  }
}

.total-info {
  margin-bottom: 48px;

  &__header {
    min-height: 64px;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__block {
    border-radius: 16px;
    width: 380px;
    min-width: 286px;
    margin-right: 24px;
    padding: 16px 20px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #7677bc;

    &-title {
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: white;
      opacity: 0.8;
    }

    &-value {
      margin-top: 8px;
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
      color: white;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 62px;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.total-orders {
  &__content {
    display: flex;
    flex-wrap: wrap;
  }
}

.market-analytics {
  max-width: 1240px;
  padding: 28px 4px 4px 28px;
  margin-bottom: 24px;

  &__total {
    background-color: #7677bc;
    border-radius: 16px;

    .market-analytics__title {
      color: white;
    }

    .analytics__block-title {
      color: white;
    }
  }

  &__container {
    width: 100%;

    @media (max-width: 445px) {
      display: flex;
    }
  }

  &__title {
    min-height: 40px;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
  }

  &__content-container {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }
}
