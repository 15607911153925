.header {
  display: flex;
  align-items: center;

  &__menu {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-left: 12px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      width: 36px;
      height: 36px;
      margin: 0 !important;
      color: black;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(133, 133, 133, 0.2);
    }
  }

  &__title {
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: 240px;
  }

  &__local {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  &__language {
    cursor: pointer;
    margin-right: 16px;

    &-menu {
      margin-top: 8px;
      z-index: 1000 !important;
      border-radius: 12px !important;
    }

    &__icon {
      margin-left: 4px;
      display: inline-flex;
      width: 18px;
      height: 18px;
    }

    &-text {
      font-weight: 500;

      & .icon {
        margin-left: 6px !important;
        color: #858897 !important;
      }
    }
  }

  &__user {
    font-size: 18px;
    min-width: 150px;

    &-info {
      margin-left: 10px;
    }

    &-role {
      margin-top: 4px;
      font-size: 14px;
      color: #a0aeb5;
    }
  }
}
