.orders-block {
  background-color: white;
  border-radius: 0 12px 12px 12px;

  @media (max-width: 445px) {
    overflow: auto;
  }

  &__tags {
    margin-top: 24px;
    display: inline-flex;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &-item {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      padding: 4px 10px 4px 10px;
      margin: 4px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      background-color: rgba(#a0a3b5, 0.1);

      @media (max-width: 445px) {
        font-size: 12px;
      }
    }
  }

  &__divider {
    background-color: #eef1f6;
    height: 1px;
    margin-left: 24px;
    margin-right: 24px;
  }

  &__header {
    display: flex;
    padding: 11px 24px 10px 24px;
    border-bottom: 1px solid #eef1f6;

    &-value {
      font-size: 12px;
      color: #a4aabf;
      flex: 1;
    }
  }

  &__list {
    padding: 0 6px 6px 6px;
  }

  &__num {
    flex-basis: 12%;
    min-width: 125px;

    @media (max-width: 445px) {
      min-width: 90px;
    }

    &-value {
      margin-right: 8px;
    }
  }

  &__address {
    flex-basis: 46%;

    @media (max-width: 445px) {
      min-width: 145px;
    }
  }

  &__weight {
    flex-basis: 8%;
    min-width: 80px;

    @media (max-width: 445px) {
      min-width: 75px;
    }
  }

  &__courier {
    flex-basis: 13%;
    min-width: 130px;

    @media (max-width: 445px) {
      min-width: 110px;
    }
  }

  &__payment {
    flex-basis: 11%;
    min-width: 110px;

    @media (max-width: 445px) {
      min-width: 90px;
    }
  }

  &__status {
    flex-basis: 11%;
    min-width: 115px;

    @media (max-width: 445px) {
      min-width: 95px;
    }
  }
}
