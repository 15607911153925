.shift-schedule {
  padding: 12px;

  &__report {
    margin-left: auto;
    margin-top: 20px;
    height: 52px;
    min-width: 182px;
    width: 182px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #7677bc;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    color: #7677bc;

    @media (max-width: 445px) {
      height: 46px;
      flex: 1 1;
      min-width: 124px;
      width: 100%;
      font-size: 13px;
    }

    &-dropdown {
      height: 52px;
      width: 100%;
      padding: 0 18px 0 18px;
      display: flex !important;
      align-items: center !important;

      & > div {
        width: 100%;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(#7677bc, 0.1);
    }
  }

  &__add {
    margin-left: auto;
    margin-top: 20px;
    height: 48px;
    padding: 0 24px 0 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    background-color: #7677bc;

    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: white;
    }
  }

  &-filter {
    display: flex;

    &__field {
      margin-top: 20px;
      margin-right: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-label {
        max-width: 160px;
        margin-right: 16px;
      }

      &-value {
        width: 300px;
        max-width: 90%;
      }
    }
  }

  table, th, tr, td {
    border-collapse: collapse;
  }

  &__table {
    margin-top: 24px;
    width: 100%;
    border-radius: 16px;

    &-header {
      height: 72px;
      border-radius: 16px 16px 0 0;

      &__cell-first {
        padding-left: 16px;
        text-align: left;
        width: 178px;
        max-width: 178px;
        color: white;
        white-space: pre-line;
        border-radius: 16px 0 0 0;
        background-color: #7677bc;
      }

      &__cell {
        color: white;
        border: 1px solid rgba(#d9dfe4, 0.7);
        background-color: #7677bc;
      }

      &__cell-last {
        width: 48px;
        max-width: 48px;
        color: white;
        border-radius: 0 16px 0 0;
        background-color: #7677bc;
      }
    }

    &-sub {
      height: 44px;
      background-color: #f5f7fb;

      &__cell-first {
        padding-left: 16px;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid rgba(#d9dfe4, 0.7);
      }

      &__cell {
        border: 1px solid rgba(#d9dfe4, 0.7);
        text-align: center;
      }

      &__cell-last {
        border: 1px solid rgba(#d9dfe4, 0.7);
      }
    }

    &-row {
      height: 82px;
      background-color: white;

      &__cell-first {
        padding-left: 16px;
        padding-right: 6px;
        width: 178px;
        max-width: 178px;
        border: 1px solid rgba(#d9dfe4, 0.7);
      }

      &__cell {
        text-align: center;
        border: 1px solid rgba(#d9dfe4, 0.7);

        &-shift {
          display: inline-block;
          width: 16px;
          height: 16px;
          background-color: #6fcf97;
          border-radius: 50%;

          &-full-late {
            background-color: #eb5757;
          }
        }

        &-in-time {
          background: rgba(111, 207, 151, 0.1);
        }

        &-full-late {
          background: rgba(216, 85, 63, 0.1);
        }
      }

      &__cell-last {
        text-align: center;
        width: 48px;
        max-width: 48px;
        border: 1px solid rgba(#d9dfe4, 0.7);
        cursor: pointer;
      }
    }

    &-item {
      &__num {
        line-height: 20px;
        color: #858897;
      }

      &__name {
        line-height: 20px;
        color: #1d1d1b;
      }

      &__phone {
        line-height: 20px;
        color: #858897;
      }
    }
  }
}
