$imageHeight: 194px;

.product-card {
  margin: 10px 0 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  width: 280px;
  position: relative;
  border-radius: 24px;
  padding: 3px;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  &__label {
    padding-right: 12px;
    padding-left: 12px;
    position: absolute;
    left: 10px;
    top: 10px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #7677bc;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  &__chain {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);

    &-num {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      background-color: #7677bc;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 24px;
      color: white;
    }

    &-empty {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      color: white;
      border: 2px solid #a0a3b5;
    }

    &:hover {
      cursor: pointer;
    }
  }


  &__hide {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);

    &-icon {
      margin: 0 !important;
      color: rgba(0, 0, 0, 0.4);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__hide-disable {
    &:hover {
      cursor: default;
    }
  }

  &__tag {
    position: absolute;
    right: -6px;
    top: $imageHeight - 38px;
    width: 48px;
    height: 48px;

    &__icon {
      color: white;
    }
  }

  &__remove {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);

    &-icon {
      margin: 0 !important;
      color: rgba(0, 0, 0, 0.4);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__seller-info {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);

    &-icon {
      width: 32px;
      height: 32px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__rating {
    position: absolute;
    left: 10px;
    top: $imageHeight - 38px;
    padding: 4px 10px 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.9);

    &-icon {
      width: 20px;
      height: 20px;
    }

    &-text {
      margin-left: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  &__image {
    width: 274px;
    height: $imageHeight;
    object-fit: cover;
    background-color: white;
    border-radius: 24px 24px 16px 16px;

    &__loading {
      width: 220px;
      height: 160px;
      position: absolute;
      z-index: 2;

      &-placeholder {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__info {
    margin: 16px;
    min-height: 64px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &-name {
      margin-top: 4px;
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      word-break: break-word;
    }

    &-price {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    &-source-price {
      margin-left: 8px;
      font-size: 16px;
      color: #7677bc;
    }

    &-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #bec2cd;
      margin-left: 8px;
      margin-right: 8px;
    }

    &-seller {
      &__name {
        font-size: 16px;
        line-height: 24px;
        color: #858897;
      }

      &__location {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 24px;
        color: #858897;
      }
    }
  }

  &__price {
    margin-top: auto;
  }

  &__divider {
    margin-top: 16px;
    margin-bottom: 12px;
    height: 1px;
    background-color: #bec2cd;
    opacity: 0.5;
  }

  &__modal {
    padding: 32px;

    &__title {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
