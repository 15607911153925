.product-item-container {
  flex: 1;
  min-width: 396px;
  max-width: 50%;

  @media (max-width: 445px) {
    max-width: 100%;
    min-width: auto;
  }
}

.product-item {
  margin: 12px 12px 12px 12px;
  display: flex;
  padding: 16px;
  border-radius: 20px;
  background-color: #f7f8fa;

  &__image {
    width: 96px;
    height: 96px;
    min-width: 96px;
    min-height: 96px;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 445px) {
      width: 72px;
      height: 72px;
      min-width: 72px;
      min-height: 72px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__info {
    margin-left: 16px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    &-item {
      margin-right: 6px;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
  }

  &__price {
    min-width: 48px;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 18.75px;
  }

  &__source-price {
    margin-top: 8px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 18.75px;
    color: #7677bc;
  }

  &__unit {
    min-width: 52px;
    height: 12px;
    margin-top: 4px;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #a0a3b5;

    &-label {
      display: inline;
      white-space: pre;
    }
  }

  &__country {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #858897;
    word-break: break-word;

    @media (max-width: 445px) {
      font-size: 13px;
    }
  }

  &__form {
    display: flex;

    @media (max-width: 445px) {
      flex-wrap: wrap;
    }
  }

  &__input {
    margin-right: 4px;
    width: 60px;

    @media (max-width: 445px) {
      margin-bottom: 8px;
    }
  }

  &__btn {
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #7677bc;
    cursor: pointer;

    @media (max-width: 445px) {
      margin-top: 4px;
      width: 30px;
      height: 30px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      @media (max-width: 445px) {
        width: 20px;
      }
    }
  }

  &__error {
    margin-top: 6px;
    color: #d8553f;
    font-size: 12px;
    font-weight: 400;
  }

  &__quantity {
    margin-top: auto;
    margin-left: 16px;
    font-weight: 500;
    font-size: 14px;

    &-add {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: #7677bc;
      }

      &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #7677bc;
      }
    }
  }

  &__weight {
    display: flex;
    align-items: center;
  }

  &__edit-btn {
    margin-left: 10px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #7677bc;
    cursor: pointer;

    &__icon {
      width: 16px !important;
      height: 16px !important;
      font-size: 16px !important;
      margin: 0 !important;
      color: #a0a3b5 !important;
    }
  }
}
