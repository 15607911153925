.moderate-product__price {
  flex-wrap: wrap;

  & > div {
    min-width: 124px;
    margin-bottom: 12px;
    padding-right: 12px;
  }

  &-unit {
    min-width: 172px !important;
  }

  &-price {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 84px;
    padding: 8px 16px 8px 16px;
    border-radius: 12px;
    background-color: #f5f7fb;
    margin-left: -12px;

    &-value {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }
}
